import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { MessagesComponent } from './messages';
import { FormSettingsComponent } from './form-settings';
import { PS3ConfigComponent } from './components/ps3-config';
import { AuthGuard } from './_helpers';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/penetration-register', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/penetration-register/:approval/:datePeriod', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/penetration-register/:approval/:datePeriod/:penetrationIndex', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/penetration-register/:approval/:datePeriod/:dateStart/:dateEnd', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/penetration-register/:approval/:datePeriod/:dateStart/:dateEnd/:penetrationIndex', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/linear-register/:approval', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/linear-register/:approval/:linearIndex', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/dayworks-marker/:approval/:datePeriod', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/dayworks-marker/:approval/:datePeriod/:penetrationIndex', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/dayworks-marker/:approval/:datePeriod/:dateStart/:dateEnd', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/dayworks-marker/:approval/:datePeriod/:dateStart/:dateEnd/:penetrationIndex', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'project/:searchQuery/:searchLimit/:includeArchived/:projectId/dayworks-marker/:approval/:datePeriod/:dateStart/:dateEnd/:penetrationIndex/:itemIndex', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'messages', component: MessagesComponent, canActivate: [AuthGuard] },
  { path: 'messages/:messageId', component: MessagesComponent, canActivate: [AuthGuard] },
  { path: 'messages/:messageId/:workflowId', component: MessagesComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'form-settings', component: FormSettingsComponent, canActivate: [AuthGuard] },
  { path: 'admin/ps3-config', component: PS3ConfigComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
