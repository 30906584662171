import { Component, OnInit } from '@angular/core';
import { FormSettingService } from '@app/_services/form-setting.service';

import { first } from 'rxjs/operators';

@Component({
  selector: '[ps3-config]',
  templateUrl: './ps3-config.component.html',
  styleUrls: ['./ps3-config.component.scss']
})
export class PS3ConfigComponent implements OnInit {

  loading:Boolean = true;
  submitted:Boolean = false;
  saved:Boolean = false;
  imageUploading:Boolean = false;

  signatureFile;
  signatureFileData;
  tempSignature;
  ps3Data = [];

  authorName = '';
  authorCompany = '';
  registrationNumber = '';
  address = '';
  postcode = '';
  businessName = '';
  fax = '';
  email = '';
  mobile = '';

	constructor(
    private formSettingService: FormSettingService
	) { }

  ngOnInit() {
    this.formSettingService.getPS3Config().pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.ps3Data = data.data;
            this.authorName = data.data.authorName;
            this.authorCompany = data.data.authorCompany;
            this.registrationNumber = data.data.registrationNumber;
            this.address = data.data.address;
            this.postcode = data.data.postcode;
            this.businessName = data.data.businessName;
            this.fax = data.data.fax;
            this.email = data.data.email;
            this.mobile = data.data.mobile;
          }
        },
        error => {

        });
  }

  setSignatureFile(evt) {
    this.signatureFileData = evt.target.files[0];
    let reader = new FileReader();
    let $this = this;
    reader.addEventListener('load', function() {
      $this.tempSignature = reader.result;
    }, false);
    reader.readAsDataURL(this.signatureFileData);
  }
  signatureUpload() {
    if( !this.imageUploading ) {
      this.imageUploading = true;
      let formData = new FormData();
      formData.append('file', this.signatureFileData);

      this.formSettingService.uploadSignature(formData).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            this.imageUploading = false;
          },
          error => {
            this.imageUploading = false;
          });
    }
  }
  updatePS3Config() {
    if( !this.submitted ) {
      this.submitted = true;
      this.saved = false;
      let dataArray = {
        "authorName": this.authorName,
        "authorCompany": this.authorCompany,
        "registrationNumber": this.registrationNumber,
        "address": this.address,
        "postcode": this.postcode,
        "businessName": this.businessName,
        "fax": this.fax,
        "email": this.email,
        "mobile": this.mobile
      };
      this.formSettingService.putPS3Config(dataArray).pipe(first())
        .subscribe(
          data => {
            this.submitted = false;
            this.saved = true;
          },
          error => {
            this.submitted = false;
          });
    }
  }
}
