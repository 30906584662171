import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

import { first } from 'rxjs/operators';
import { ProjectService } from '@app/_services/project.service';

@Component({
  selector: '[gf-project-contacts]',
  templateUrl: './project-contacts.component.html',
  styleUrls: ['./project-contacts.component.scss']
})
export class ProjectContactsComponent implements OnInit, OnChanges {

  loading: Boolean = false;

	constructor(
    private projectService: ProjectService
	) { }

  @Input()
  projectId;
  @Input()
  tabStyles;
  @Input()
  data;
  @Input()
  hideActions = false;
  @Output()
  tabControl = new EventEmitter<any>();

  projectContacts = [];

  isFormOpen: Boolean = false;

  contactFirstName = '';
  contactLastName = '';
  contactEmail = '';
  contactCompany = '';
  contactPhone = '';

  removing = null;
  editingIndex;
  editingId;

  ngOnChanges(changes: SimpleChanges) {
    const projectId: SimpleChange = changes.projectId;
    if( projectId && projectId.currentValue ) {
      this.loading = true;
      this.updateContactList();
    }
  }

  ngOnInit() {
  }

  openContactForm() {
    this.isFormOpen = true;
  }
  closeContactForm() {
    this.isFormOpen = false;
  }

  close() {
    this.tabControl.next({tabString: 'projectContacts', tabAttr: 'visible', tabAttrValue: false});
  }

  updateContactList() {
    this.projectContacts = [];
    this.projectService.getProjectContacts(this.projectId).pipe(first())
      .subscribe(
        data => {
          if( data.data ) {
            this.projectContacts = data.data;
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        }
      )
  }

  saveContact() {
    if( this.contactFirstName.length > 0 && this.contactLastName.length > 0 && this.contactEmail.length > 0 ) {
      let contactData = {
        firstName: this.contactFirstName,
        lastName: this.contactLastName,
        emailAddress: this.contactEmail,
        companyName: this.contactCompany,
        phoneNumber: this.contactPhone,
        projectId: this.projectId
      }
      if( this.editingId ) {
        this.projectService.updateProjectContact(this.editingId, contactData).pipe(first())
          .subscribe(
            data => {
              this.isFormOpen = false;
              this.contactFirstName = '';
              this.contactLastName = '';
              this.contactEmail = '';
              this.contactCompany = '';
              this.contactPhone = '';
              if( data.data ) {
                this.projectContacts[this.editingIndex] = data.data;
                this.data = [];
                this.editingIndex = '';
              }
              this.editingId = '';
            },
            error => {
              console.log(error);
              this.editingId = '';
            }
          );
      } else {
        this.projectService.createProjectContact(contactData).pipe(first())
          .subscribe(
            data => {
              this.isFormOpen = false;
              this.contactFirstName = '';
              this.contactLastName = '';
              this.contactEmail = '';
              this.contactCompany = '';
              this.contactPhone = '';
              if( data.data ) {
                this.projectContacts.push(data.data);
              }
            },
            error => {
              console.log(error);
            }
          );
      }
    }
  }

  editContact(index, contactData) {
    this.data = contactData;
    this.editingIndex = index;
    this.contactFirstName = contactData.firstName;
    this.contactLastName = contactData.lastName;
    this.contactEmail = contactData.emailAddress;
    this.contactCompany = contactData.companyName;
    this.contactPhone = contactData.phoneNumber;
    this.isFormOpen = true;
    this.editingId = contactData.id;
    console.log(contactData);
  }

  deleteContact(index, contactId) {
    // if( !this.removing || this.removing != contactId ) {
    //   this.removing = contactId;
    // } else if( this.removing && this.removing == contactId ) {
    //   this.removing = null;
    //   this.projectContacts.splice(index, 1);
    // }
    this.projectContacts.splice(index, 1);
    this.projectService.removeProjectContact(contactId).pipe(first())
      .subscribe(
        data => {
        },
        error => {
          console.log(error);
        }
      );
  }

}
