import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatDate, Location } from '@angular/common';
import { PenetrationService } from '@app/_services/penetration.service';
import { LinearService } from '@app/_services/linear.service';
import { AuthenticationService } from '@app/_services';
import { JwtHelperService } from "@auth0/angular-jwt";

import { first } from 'rxjs/operators';

@Component({
  selector: '[gf-daywork-markers]',
  templateUrl: './daywork-markers.component.html',
  styleUrls: ['./daywork-markers.component.scss']
})
export class DayworkMarkersComponent implements OnInit, OnChanges {

  loading: Boolean = false;
  submitted: Boolean = false;
  rejecting: Boolean = false;

  selectedDaywork;

  showUnapprovedDaywork: Boolean = true;
  showApprovedDaywork: Boolean = true;

  dateRange = 1;
  dateStartInput;
  dateEndInput;
  thisMonthStart;
  thisMonthEnd;
  lastMonthStart;
  lastMonthEnd;

  rejectMessage = '';

  @Input()
  projectId;
  @Input()
  selectedPenetrationIndex;
  @Input()
  tabData;
  @Input()
  data;
  @Input()
  hideActions = true;
  @Output()
  tabControl = new EventEmitter<any>();

  locationString = '';
  penetrationIndex: number;
  itemIndex: number;

  penetrations = [];
  selectedPenetration;
  checkedPenetration = [];
  collapsedWidth = '';
  bulkCheck;
  toggleDayworks = 1;

  canApprove: Boolean = false;
  canReject: Boolean = false;

  @Input()
  searchQuery = '';
  @Input()
  searchLimit = 10;
  @Input()
  includeArchived: boolean = false;

	constructor(
    private penetrationService: PenetrationService,
    private linearService: LinearService,
    private route: ActivatedRoute,
    private location: Location,
    private authenticationService: AuthenticationService
	) {
    let tms = new Date();
    if( tms.getDate() >= 16 ) {
      tms.setMonth(tms.getMonth() + 1);
    }
    tms.setDate(16);
    tms.setMonth(tms.getMonth() - 1);
    if( tms.getMonth() < 1 ) {
      tms.setFullYear(tms.getFullYear() - 1);
    }
    this.thisMonthStart = tms;
    let tme = new Date();
    if( tme.getDate() >= 16 ) {
      tme.setMonth(tme.getMonth() + 1);
    }
    tme.setDate(15);
    this.thisMonthEnd = tme;
    let lms = new Date();
    if( lms.getDate() >= 16 ) {
      lms.setMonth(lms.getMonth() + 1);
    }
    lms.setDate(16);
    lms.setMonth(lms.getMonth() - 2);
    if( lms.getMonth() < 1 ) {
      lms.setFullYear(lms.getFullYear() - 1);
    }
    this.lastMonthStart = lms;
    let lme = new Date();
    if( lme.getDate() >= 16 ) {
      lme.setMonth(lme.getMonth() + 1);
    }
    lme.setDate(15);
    lme.setMonth(lme.getMonth() - 1);
    if( lme.getMonth() < 1 ) {
      lme.setFullYear(lme.getFullYear() - 1);
    }
    this.lastMonthEnd = lme;

    this.route.paramMap.subscribe(params => {
      if( params.get('projectId') ) {
        this.projectId = params.get('projectId');
        if( params.get('approval') ) {
          if( params.get('approval') == 'approved' ) {
            this.showUnapprovedDaywork = false;
            this.showApprovedDaywork = true;
          } else if( params.get('approval') == 'unapproved' ) {
            this.showUnapprovedDaywork = true;
            this.showApprovedDaywork = false;
          } else {
            this.showUnapprovedDaywork = true;
            this.showApprovedDaywork = true;
          }
        }
  			if( params.get('datePeriod') ) {
          if( params.get('datePeriod') == 'last-month' ) {
            this.dateRange = 2;
          } else if( params.get('datePeriod') == 'custom' ) {
            this.dateRange = 0;
          } else {
            this.dateRange = 1;
          }
  			}
  			if( params.get('dateStart') && params.get('dateStart') != 'null' ) {
  				this.dateStartInput = params.get('dateStart');
  			}
  			if( params.get('dateEnd') && params.get('dateEnd') != 'null' ) {
  				this.dateEndInput = params.get('dateEnd');
  			}
  			if( params.get('penetrationIndex') ) {
          this.penetrationIndex = parseInt(params.get('penetrationIndex'));
  			}
        if( params.get('itemIndex') ) {
          this.itemIndex = parseInt(params.get('itemIndex'));
  			}
      }
		});
  }

  ngOnChanges(changes: SimpleChanges) {
    const projectId: SimpleChange = changes.projectId;
    const selectedPenetrationIndex: SimpleChange = changes.selectedPenetrationIndex;
    if( projectId && projectId.currentValue ) {
      this.penetrations = [];
      this.selectedDaywork = '';
      this.getPenetrationList();
    }
    if( selectedPenetrationIndex && selectedPenetrationIndex.currentValue > 0 && (selectedPenetrationIndex.currentValue != selectedPenetrationIndex.previousValue) ) {
      if( this.penetrations[selectedPenetrationIndex.currentValue] ) {
        this.selectPenetration(selectedPenetrationIndex.currentValue, this.penetrations[selectedPenetrationIndex.currentValue]);
      }
    }
  }

  ngOnInit() {
    const helper = new JwtHelperService();

    let currentUser = this.authenticationService.currentUserValue;
    let currentUserRoles = [];
    if( helper.decodeToken(currentUser.jwt) && helper.decodeToken(currentUser.jwt).rol ) {
      currentUserRoles = helper.decodeToken(currentUser.jwt).rol;
    }

    let headerElement = <HTMLElement>document.querySelector('.header');
    let cardHeaderWidth = window.innerHeight - headerElement.offsetHeight;
    this.collapsedWidth = 'calc('+ cardHeaderWidth +'px - 2rem)';

    if( currentUserRoles.includes('superadmin') ||
        currentUserRoles.includes('admin') ||
        currentUserRoles.includes('supervisor') ) {
      this.canApprove = true;
      this.canReject = true;
    }
  }

  approve() {
    this.submitted = true;
    this.penetrationService.approveDayworks(this.selectedDaywork.id).pipe(first())
      .subscribe(
        data => {
          if( data ) {
            this.selectedDaywork.isApproved = true;
            this.submitted = false;
            this.rejectMessage = '';
          }
        },
        error => {
          console.log(error);
          this.submitted = false;
        }
      );
  }
  reject() {
    if( !this.rejecting ) {
      this.rejecting = true;
    } else {
      this.rejecting = false;
      this.submitted = true;
      this.penetrationService.rejectDayworks(this.selectedDaywork.id, this.rejectMessage).pipe(first())
        .subscribe(
          data => {
            this.selectedDaywork.isApproved = false;
            this.submitted = false;
            this.rejectMessage = '';
          },
          error => {
            console.log(error);
            this.submitted = false;
          }
        );
    }
  }

  customRange() {
    if( this.dateStartInput || this.dateEndInput ) {
      this.dateRange = 0;
    }
  }
  presetRange() {
    this.dateStartInput = '';
    this.dateEndInput = '';
  }
  generateReport() {
    if( !this.loading ) {
      this.loading = true;
      let reportData = {
        projectId: this.projectId,
        ids: this.checkedPenetration
      }
      this.penetrationService.sendPenetrationReport(reportData).pipe(first())
        .subscribe(
          data => {
            this.checkedPenetration = [];
            this.bulkCheck = false;
            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        );
    }
  }

  getPenetrationList() {
    if( !this.loading ) {
      this.loading = true;

      let dateStart;
      let dateEnd;
      this.route.paramMap.subscribe(params => {
        this.locationString = '/project/'+ this.searchQuery +' /'+ this.searchLimit +'/'+ this.includeArchived +'/'+ this.projectId +'/dayworks-marker/';
      });

      if( this.showUnapprovedDaywork && !this.showApprovedDaywork ) {
        this.locationString += 'unapproved/';
      } else if( !this.showUnapprovedDaywork && this.showApprovedDaywork ) {
        this.locationString += 'approved/';
      } else {
        this.locationString += 'all/';
      }
      if( this.dateRange == 0 ) {
        this.locationString += 'custom/';
        if( this.dateStartInput ) {
          dateStart  = formatDate(this.dateStartInput, 'yyyy-MM-dd', 'en');
          this.locationString += dateStart +'/';
        } else {
          this.locationString += 'null/';
        }
        if( this.dateEndInput ) {
          dateEnd = formatDate(this.dateEndInput, 'yyyy-MM-dd', 'en');
          this.locationString += dateEnd;
        } else {
          this.locationString += 'null';
        }
      }
      if( this.dateRange == 1 ) {
        this.locationString += 'this-month';
        if( this.thisMonthStart )
          dateStart  = formatDate(this.thisMonthStart, 'yyyy-MM-dd', 'en');
        if( this.thisMonthEnd )
          dateEnd = formatDate(this.thisMonthEnd, 'yyyy-MM-dd', 'en');
      }
      if( this.dateRange == 2 ) {
        this.locationString += 'last-month';
        if( this.lastMonthStart )
          dateStart  = formatDate(this.lastMonthStart, 'yyyy-MM-dd', 'en');
        if( this.lastMonthEnd )
          dateEnd = formatDate(this.lastMonthEnd, 'yyyy-MM-dd', 'en');
      }

      this.location.replaceState(this.locationString);

      this.penetrationService.getDayworksMarker(this.projectId, dateStart, dateEnd, this.showUnapprovedDaywork, this.showApprovedDaywork).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.penetrations = data.data;

              if( this.penetrationIndex && data.data[this.penetrationIndex - 1] ) {
                this.selectDaywork(this.penetrationIndex - 1, data.data[this.penetrationIndex - 1]);
              }
              this.penetrationIndex = null;
              if( this.itemIndex && data.data[this.penetrationIndex - 1] ) {
                this.selectDaywork(this.penetrationIndex - 1, data.data[this.penetrationIndex - 1]);
              }
            }
            this.loading = false;
          },
          error => {
            this.loading = false;
          });
    }
  }
  selectDaywork(index, penetration) {
    this.selectedDaywork = penetration;
    console.log(this.selectedDaywork);
    this.location.replaceState(this.locationString + '/' + parseInt(index + 1));
  }
  selectPenetration(index, penetration) {
    if( (this.selectedPenetration && this.selectedPenetration.id !== penetration.id) || !this.selectedPenetration ) {
      this.selectedPenetration = penetration;
      this.selectedPenetrationIndex = index;
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'penetrationId', tabAttrValue: penetration.id});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'data', tabAttrValue: {number: penetration.number}});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'selectedPenetrationIndex', tabAttrValue: index});

      if( penetration.registerPreparationWorkers ) {
        this.showPenetrationDetail();
        this.penetrationService.getRegisterPreparation(penetration.id).pipe(first())
          .subscribe(
            data => {
              if( data && data.data ) {
                this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'data', tabAttrValue: data.data});
              }
            },
            error => {
            });
      }
      if( penetration.penetrationHoleSizeFormSettingId ) {
        this.showPenetrationDetail();
        this.penetrationService.getPenetration(penetration.id).pipe(first())
          .subscribe(
            data => {
              if( data && data.data ) {
                this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'data', tabAttrValue: data.data});
              }
            },
            error => {
            });
      }
      if( penetration.linearTypeId ) {
        this.showLinearDetail();
        this.linearService.getLinear(penetration.id).pipe(first())
          .subscribe(
            data => {
              if( data && data.data ) {
                if( data.data.linearTypeId == 1 )
                  data.data.linearType = 'Door';
                if( data.data.linearTypeId == 2 )
                  data.data.linearType = 'Edge-of-wall joint';
                if( data.data.linearTypeId == 3 )
                  data.data.linearType = 'Head-of-wall joint';
                if( data.data.linearTypeId == 4 )
                  data.data.linearType = 'Trapezium joint';
                if( data.data.linearTypeId == 5 )
                  data.data.linearType = 'Vertical Joint';
                if( data.data.linearTypeId == 6 )
                  data.data.linearType = 'Stairs';
                if( data.data.linearTypeId == 7 )
                  data.data.linearType = 'Window';
                this.tabControl.next({tabString: 'linearDetails', tabAttr: 'data', tabAttrValue: data.data});
              }
            },
            error => {
            });
      }

      // this.location.replaceState(this.locationString + '/' + parseInt(index + 1));
    }
  }

  showLinearDetail() {
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'left', tabAttrValue: 'calc(120px + 2rem)'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});

    this.tabControl.next({tabString: 'linearDetails', tabAttr: 'visibility', tabAttrValue: 'visible'});

    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: true});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'left', tabAttrValue: 'calc(120px + 2rem)'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'left', tabAttrValue: 'calc(50% + 60px + 3rem)'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'width', tabAttrValue: 'calc(50% - 120px - 0.25rem)'});
    } else {
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }

  bulkSelectToggle() {
    if( this.bulkCheck ) {
      this.penetrations.forEach(penetration => {
        if( !this.checkedPenetration.includes(penetration.id) ) {
          this.checkedPenetration.push(penetration.id);
        }
      });
    } else {
      this.checkedPenetration = [];
    }
  }
  toggleCheckedPenetration(penetrationId) {
    if( this.checkedPenetration.includes(penetrationId) ) {
      this.checkedPenetration.splice(this.checkedPenetration.indexOf(penetrationId), 1);
    } else {
      this.checkedPenetration.push(penetrationId);
    }
    if( this.checkedPenetration.length == 0 ) {
      this.bulkCheck = false;
    }
  }

  showPenetrationDetail() {
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'left', tabAttrValue: 'calc(120px + 2rem)'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});

    this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'visibility', tabAttrValue: 'visible'});

    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: true});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'left', tabAttrValue: 'calc(120px + 2rem)'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'left', tabAttrValue: 'calc(50% + 60px + 3rem)'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'width', tabAttrValue: 'calc(50% - 120px - 0.25rem)'});
    } else {
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }

  collapse() {
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'isCollapsed', tabAttrValue: true});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'left', tabAttrValue: 'calc(180px + 3rem)'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'width', tabAttrValue: 'calc((100% - 180px) - 4rem)'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr: 'left', tabAttrValue: 'calc(180px + 3rem)'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr: 'width', tabAttrValue: 'calc((100% - 180px) - 4rem)'});
  }
  uncollapse() {
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'hideActions', tabAttrValue: false});
    if( this.tabData.penetrationDetails.visibility == 'visible' ) {
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'left', tabAttrValue: 'calc(50% + 60px + 3rem)'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'width', tabAttrValue: 'calc(50% - 120px - 0.25rem)'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'left', tabAttrValue: 'calc(50% + 60px + 3rem)'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'width', tabAttrValue: 'calc(50% - 120px - 0.25rem)'});
    }
  }

  close() {
    if( document.querySelector('.gf-datatable-wrap .is-active') ) {
      document.querySelector('.gf-datatable-wrap .is-active').classList.remove('is-active');
    }
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideProjectContacts', tabAttrValue: false});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'left', tabAttrValue: '100%'});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: 'calc(40%)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
    } else {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }
}
