import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class ProjectService {

  constructor(
    private http: HttpClient,
    private _authService: AuthenticationService
  ) {}

  getProjectList(includeArchived = false, limit = 10, queryString = '') {
    let url = 'api/v2/home/search?q='+ queryString +'&start=0&limit=' + limit;
    if( includeArchived ) {
      url = 'api/v2/home/search?q='+ queryString +'&start=0&limit='+ limit +'&includeArchived=true';
    }
    return this.http.get<any>(`${environment.apiUrl}/${url}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  searchProject(keyword) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/home/search?q=${keyword}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getProjectById(projectId) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/project/${projectId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getProjectContacts(projectId) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/contact?projectId=${projectId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  createProjectContact(contactData) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/contact`, contactData, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  updateProjectContact(contactId, contactData) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/contact/${contactId}`, contactData, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  removeProjectContact(contactId) {
    return this.http.delete<any>(`${environment.apiUrl}/api/v2/contact/${contactId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

	create(projectArray) {
		return this.http.post<any>(`${environment.apiUrl}/api/v2/project`, projectArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
	}

  update(projectId, projectArray) {
		return this.http.put<any>(`${environment.apiUrl}/api/v2/project/${projectId}`, projectArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
	}

  createFloor(floorArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/floor/upload`, floorArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  updateFloor(floorId, floorArray) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/floor/${floorId}`, floorArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  deleteFloor(floorId) {
    return this.http.delete<any>(`${environment.apiUrl}/api/v2/floor/${floorId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  closeOutProject(projectId, contactArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/project/${projectId}/close-out`, contactArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  generatePS3(projectId, contactArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/project/${projectId}/generate-ps3`, contactArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this._authService.currentUserValue.jwt
    });

    return { headers: headers };
  }
}
