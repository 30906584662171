import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class FormSettingService {

  constructor(
    private http: HttpClient,
    private _authService: AuthenticationService
  ) {}

  getFormSettingsList(id = null) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/form-setting/legacy?optionTypeId=${id}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getPenetrationRegisterMarker() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/form-setting/penetration-register-marker`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getSingleTA() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/form-setting/task-analysis`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getSurveyChecklist() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/form-setting/survey-checklist`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getPenetrationEstimateMarker() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/form-setting/penetration-estimate-marker`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getSiteInspection() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/form-setting/site-inspection`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getLookupData() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/form-setting/lookup-data`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  createFormSetting(dataArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/form-setting`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getProduct() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/progressive/product`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  getProductBrand() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/product/brand`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  getProductCategory() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/product/category`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  getProductUnit() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/product/unit`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  createProduct(dataArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/product`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  deleteProduct(productId) {
    return this.http.delete<any>(`${environment.apiUrl}/api/v2/product/${productId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  getServiceTypeCategory() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/service-type/category`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  getServiceType() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/service-type`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  createServiceType(dataArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/service-type`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  deleteServiceType(serviceTypeId) {
    return this.http.delete<any>(`${environment.apiUrl}/api/v2/service-type/${serviceTypeId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }  
  getConstructionSystemCategory() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/construction-system/category`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  getConstructionSystem() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/construction-system`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  createConstructionSystem(dataArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/construction-system`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  deleteConstructionSystem(ConstructionSystemId) {
    return this.http.delete<any>(`${environment.apiUrl}/api/v2/construction-system/${ConstructionSystemId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }  

  deleteFormSetting(formSettingId) {
    return this.http.delete<any>(`${environment.apiUrl}/api/v2/form-setting/${formSettingId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  uploadSignature(dataArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/ps3-config/upload`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getPS3Config() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/ps3-config`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  putPS3Config(dataArray) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/ps3-config`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this._authService.currentUserValue.jwt,
    });

    return { headers: headers };
  }
}
