import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';

import { ProjectService } from '@app/_services/project.service';
import { MessageService } from '@app/_services/message.service';

@Component({
  selector: '[gf-message-form]',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss']
})
export class MessageFormComponent implements OnInit, OnChanges {
  loading = false;
	submitted = false;
  searching = false;
  searchingTimeout;
  searchResult = [];
  error = '';
  isCompletedToggle = false;

	constructor(
    private projectService: ProjectService,
    private messageService: MessageService,
    private location: Location
	) { }

  @Input()
  tabData;
  @Input()
  data;
  @Input()
  hideActions = false;
  @Input()
  workerList = [];
  @Output()
  tabControl = new EventEmitter<any>();

  assignToDropdownSettings = {
    idfield: 'workerId',
    textField: 'workerName',
    singleSelection: true,
    allowSearchFilter: true,
    enableCheckAll: false
  };
  workerDropdownSettings = {
    idfield: 'workerId',
    textField: 'workerName',
    allowSearchFilter: true,
    enableCheckAll: false
  };

  assignTo = [];
  includeAlso = [];
  projectName = '';
  messageSubject = '';
  messageBody = '';
  targetProjectId;
  fileQueue = [];
  filePreviews = [];

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    const data: SimpleChange = changes.data;
    if( data && data.currentValue ) {
      if( data.currentValue.selectedWorkflow ) {
        data.currentValue.selectedWorkflow.assignedToWorker.id = data.currentValue.selectedWorkflow.assignedToWorker.workerId;
        this.assignTo = [data.currentValue.selectedWorkflow.assignedToWorker];
      }
      this.error = '';
    }
  }

  projectSearch(keyword) {
    if( keyword && keyword.length > 2 ) {
      this.searching = true;
      if( this.searchingTimeout ) {
        clearTimeout(this.searchingTimeout);
      }
      this.searchingTimeout = setTimeout(() => {
        this.projectService.searchProject(keyword).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.searchResult = data.data;
            }
            this.searching = false;
          },
          error => {
            console.log(error);
            this.searching = false;
          }
        );
      }, 1500);
    } else {
      this.searching = false;
    }
  }

  setProject(projectId, projectName) {
    this.projectName = projectName;
    this.targetProjectId = projectId;
    this.searchResult = [];
  }

  createMessage() {
    if( !this.submitted ) {
      this.submitted = true;

      if( this.data && this.data.id ) {
        let messageArray = {
          body: this.messageBody,
          assignedToWorker: {
            workerId: this.assignTo[0].id
          },
          recipients: [],
          isCompleted: this.isCompletedToggle
        }
        if( this.includeAlso ) {
          this.includeAlso.forEach((item) => {
            messageArray.recipients.push({workerId: item.id});
          });
        }

        this.messageService.createWorkflow(this.data.id, messageArray).pipe(first())
          .subscribe(
            data => {
              if( data && data.data ) {
                if( this.fileQueue.length > 0 ) {
                  this.uploadImage(data.data.id);
                } else {
                  this.close();
                  this.resetForm();
                  this.submitted = false;
                }
                let originalMessage = this.tabData.messageList.data.filter((single) => {
                  return single.id == this.data.id;
                });
                if( originalMessage && originalMessage.length > 0 ) {
                  let originalMessageIndex = this.tabData.messageList.data.indexOf(originalMessage[0]);
                  let messageToPush = this.tabData.messageList.data;
                  messageToPush[originalMessageIndex].workflows.unshift(data.data);
                  this.tabControl.next({tabString: 'messageList', tabAttr: 'data', tabAttrValue: messageToPush});
                  this.tabControl.next({tabString: 'messageDetails', tabAttr: 'data', tabAttrValue: messageToPush[originalMessageIndex]});
                  this.tabControl.next({tabString: 'messageDetails', tabAttr: 'selectedWorkflow', tabAttrValue: ''});
                  this.tabControl.next({tabString: 'messageDetails', tabAttr: 'selectedWorkflowId', tabAttrValue: ''});
                  this.location.replaceState('/messages/'+ messageToPush[originalMessageIndex].id);
                }
              } else if( data && data.message ) {
                this.error = data.message;
                this.submitted = false;
              }
            },
            error => {
              console.log(error);
              this.submitted = false;
            }
          );
      } else {
        let messageArray = {
          subject: this.messageSubject,
          projectId: this.targetProjectId,
          workflows: [{
            body: this.messageBody,
            assignedToWorker: {
              workerId: this.assignTo[0].id
            },
            recipients: [],
            isCompleted: this.isCompletedToggle
          }]
        }

        if( this.includeAlso ) {
          this.includeAlso.forEach((item) => {
            messageArray.workflows[0].recipients.push({workerId: item.id});
          });
        }

        this.messageService.createMessage(messageArray).pipe(first())
          .subscribe(
            data => {
              if( data && data.data ) {
                if( this.fileQueue.length > 0 ) {
                  this.uploadImage(data.data.workflows[0].id);
                } else {
                  this.resetForm();
                  this.submitted = false;
                }
                let originalMessage = this.tabData.messageList.data;
                originalMessage.unshift(data.data);
                this.tabControl.next({tabString: 'messageList', tabAttr: 'data', tabAttrValue: originalMessage});
              } else if( data && data.message ) {
                console.log(data.message);
                this.error = data.message;
                this.submitted = false;
              }
            },
            error => {
              console.log(error);
              this.submitted = false;
            }
          );
      }
    }
  }

  resetForm() {
    this.messageSubject = '';
    this.messageBody = '';
    this.assignTo = [];
    this.includeAlso = [];
    this.searchResult = [];
    this.projectName = '';
    this.fileQueue = [];
    this.filePreviews = [];
    this.error = '';
  }

  addToFileQueue(fileInput: any) {
    let fileData = fileInput.target.files[0];
    if( fileData ) {
      this.fileQueue.push(fileData);

      var reader =  new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = (event: any) => {
        this.filePreviews.push(event.target.result);
      }
    }
  }

  uploadImage(workflowId) {
    let i = 1;
    this.fileQueue.forEach((single) => {
      let formData = new FormData();
      formData.append('file', single);
      formData.append('messageWorkflowId', workflowId);

      this.messageService.uploadAttachment(formData).pipe(first())
      .subscribe(
        data => {
          if( i == this.fileQueue.length ) {
            this.submitted = false;
            this.close();
            this.resetForm();
          }
          i++;
        },
        error => {
          console.log(error);
          i++;
        }
      );
    });
  }

  removeFromFileQueue(index) {
    this.filePreviews.splice(index, 1);
    this.fileQueue.splice(index, 1);
  }

  toggleClass(event, className) {
    if( event ) {
      let hasClass = event.target.parentElement.classList.contains(className);
      if(hasClass) {
        event.target.parentElement.classList.remove(className);
      } else {
        event.target.parentElement.classList.add(className);
      }
    }
  }

  cancelEdit() {
    if( this.data ) {
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'visibility', tabAttrValue: 'visible'});
    } else {
      this.close();
    }
  }

	onSubmit() {
		this.submitted = true;
	}
  uncollapse() {
    this.tabControl.next({tabString: 'messageForm', tabAttr: 'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'messageForm', tabAttr: 'isCollapsed', tabAttrValue: false});
  }
  close() {
    this.resetForm();
    if( window.innerWidth < 992 ) {
      this.tabControl.next({tabString: 'messageList', tabAttr: 'left', tabAttrValue: '0'});
    }
    if( this.tabData.messageList.isCollapsed ) {
      this.tabControl.next({tabString: 'messageList', tabAttr: 'width', tabAttrValue: '40%'});
      this.tabControl.next({tabString: 'messageDetails', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'messageDetails', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
    } else {
      this.tabControl.next({tabString: 'messageList', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
      this.tabControl.next({tabString: 'messageList', tabAttr: 'hideActions', tabAttrValue: false});
    }
    this.tabControl.next({tabString: 'messageList', tabAttr: 'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'messageForm', tabAttr: 'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'messageForm', tabAttr:'left', tabAttrValue: '100%'});
    // this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: '100%'});
    if( document.querySelector('.row-project.is-active') ) {
      document.querySelector('.row-project.is-active').classList.remove('is-active');
    }
  }
}
