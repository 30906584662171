import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { MessageService } from '@app/_services/message.service';

@Component({
  selector: '[gf-message-details]',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.scss']
})
export class MessageDetailsComponent implements OnInit, OnChanges {

  loading:Boolean = true;
  sendingReport: Boolean = false;
  rejecting: Boolean = false;

  @Input()
  messageId;
  @Input()
  tabData;
  @Input()
  data;
  @Input()
  hideActions = false;
  @Input()
  selectedWorkflow;
  @Input()
  selectedWorkflowId;
  @Output()
  tabControl = new EventEmitter<any>();

  collapsedWidth:any = 0;

  rejectMessageComment = '';

	constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private location: Location
	) { }

  ngOnChanges(changes: SimpleChanges) {
    const messageId: SimpleChange = changes.messageId;
    if( messageId && messageId.currentValue ) {
      if( document.querySelector('.card-message-details .gf-card-body') ) {
        document.querySelector('.card-message-details .gf-card-body').scrollTop = 0;
      }
      this.data = {};
      setTimeout(() => {
        this.tabControl.next({tabString: 'messageDetails', tabAttr:'selectedWorkflow', tabAttrValue: ''});
        this.loadMessage(messageId.currentValue);
      }, 200);
    }
  }

  ngOnInit() {
    let headerElement = <HTMLElement>document.querySelector('.header');
    let cardHeaderWidth = window.innerHeight - headerElement.offsetHeight;
    this.collapsedWidth = 'calc('+ cardHeaderWidth +'px - 2rem)';

    this.route.paramMap.subscribe(params => {
      if( params.get('workflowId') ) {
        this.selectedWorkflowId = params.get('workflowId');
      }
    });
  }

  loadMessage(messageId) {
    this.loading = true;
    this.messageService.getMessageDetail(messageId).pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.data = data.data;
            this.route.paramMap.subscribe(params => {
              if( this.selectedWorkflowId && this.data.workflows ) {
                let selectedWorkflowData = this.data.workflows.filter((single) => {
                  return single.id == this.selectedWorkflowId;
                });
                if( selectedWorkflowData && selectedWorkflowData.length > 0 ) {
                  this.selectWorkflow(selectedWorkflowData[0]);
                }
              }
            });
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  selectWorkflow(message) {
    this.tabControl.next({tabString: 'messageDetails', tabAttr:'selectedWorkflow', tabAttrValue: message});
    this.tabControl.next({tabString: 'messageDetails', tabAttr:'selectedWorkflowId', tabAttrValue: message.id});

    this.location.replaceState('/messages/'+ this.messageId + '/' + message.id);
  }

  toggleClass(event, className) {
    if( event ) {
      let hasClass = event.target.parentElement.classList.contains(className);
      if(hasClass) {
        event.target.parentElement.classList.remove(className);
      } else {
        event.target.parentElement.classList.add(className);
      }
    }
  }

  close() {
    if( this.selectedWorkflow ) {
      this.tabControl.next({tabString: 'messageDetails', tabAttr:'selectedWorkflow', tabAttrValue: ''});
    } else {
      this.tabControl.next({tabString: 'messageDetails', tabAttr:'selectedWorkflow', tabAttrValue: ''});
      if( window.innerWidth < 992 ) {
        this.tabControl.next({tabString: 'messageList', tabAttr: 'left', tabAttrValue: '0'});
      }
      this.tabControl.next({tabString: 'messageList', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
      this.tabControl.next({tabString: 'messageList', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'messageList', tabAttr: 'hideActions', tabAttrValue: false});
      this.tabControl.next({tabString: 'messageForm', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'messageDetails', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'messageDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});

      if( document.querySelector('.row-project.is-active') ) {
        document.querySelector('.row-project.is-active').classList.remove('is-active');
      }
      if( document.querySelector('.gf-datatable-wrap .is-active') ) {
        document.querySelector('.gf-datatable-wrap .is-active').classList.remove('is-active');
      }
    }
  }

  addWorkflow() {
    this.tabControl.next({tabString: 'messageList', tabAttr: 'isCollapsed', tabAttrValue: true});
    this.tabControl.next({tabString: 'messageList', tabAttr: 'width', tabAttrValue: this.collapsedWidth});

    this.tabControl.next({tabString: 'messageDetails', tabAttr: 'left', tabAttrValue: 'calc(60px + 1rem)'});
    this.tabControl.next({tabString: 'messageDetails', tabAttr: 'width', tabAttrValue: 'calc(50% - 30px - 2rem)'});

    let targetWorkflow = this.data.workflows.filter((single) => {
      return single.id == this.selectedWorkflowId;
    });
    let workflowData = this.data;
    if( targetWorkflow && targetWorkflow.length > 0 ) {
      workflowData.selectedWorkflow = targetWorkflow[0];
    }

    this.tabControl.next({tabString: 'messageForm', tabAttr:'data', tabAttrValue: workflowData});
    this.tabControl.next({tabString: 'messageForm', tabAttr:'visibility', tabAttrValue: 'visible'});
    this.tabControl.next({tabString: 'messageForm', tabAttr:'left', tabAttrValue: 'calc(60px + 50% - 30px)'});
    this.tabControl.next({tabString: 'messageForm', tabAttr:'width', tabAttrValue: 'calc(50% - 30px - 1rem)'});
  }

  approveMessage() {
    if( !this.loading ) {
      this.loading = true;
      this.messageService.approveMessage(this.messageId).pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            this.selectedWorkflow = '';
            this.close();
          },
          error => {
            console.log(error);
            this.loading = false;
          }
        );
    }
  }
  rejectMessage() {
    if( !this.loading ) {
      this.loading = true;
      this.messageService.rejectMessage(this.messageId, {message: this.rejectMessageComment}).pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            this.selectedWorkflow = '';
            this.close();
          },
          error => {
            console.log(error);
            this.loading = false;
          }
        );
    }
  }

}
