import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      let tempIt = '';
      tempIt += it;
      if( it.number ) {
        tempIt += it.number;
      }
      if( it.name ) {
        tempIt += it.name;
      }
      if( it.address ) {
        tempIt += it.address;
      }
      if( it.displayName ) {
        tempIt += it.displayName;
      }
      if( it.workerName ) {
        tempIt += it.workerName;
      }
      if( it.formSettingName ) {
        tempIt += it.formSettingName;
      }
      if( it.productName ) {
        tempIt += it.productName;
      }
      if( it.productBrand ) {
        tempIt += it.productBrand;
      }
      if( it.productCategory ) {
        tempIt += it.productCategory;
      }
      if( it.productUnit ) {
        tempIt += it.productUnit;
      }
      if( it.serviceTypeName ) {
        tempIt += it.serviceTypeName;
      }
      if( it.serviceTypeCode ) {
        tempIt += it.serviceTypeCode;
      }
      if( it.constructionSystemCategory ) {
        tempIt += it.constructionSystemCategory;
      }
      if( it.constructionSystemName ) {
        tempIt += it.constructionSystemName;
      }
      if( it.constructionSystemCode ) {
        tempIt += it.constructionSystemCode;
      }
      it = tempIt;
      if( typeof it === 'string' ) {
        return it.toLowerCase().includes(searchText);
      }
    });
   }
}
