import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  constructor(
    private http: HttpClient,
    private _authService: AuthenticationService
  ) {}

  getNotificationList() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/scheduled-notification`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  createNotification(dataArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/scheduled-notification`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  updateNotification(notificationId, dataArray) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/scheduled-notification/${notificationId}`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  deleteNotification(notificationId) {
    return this.http.delete<any>(`${environment.apiUrl}/api/v2/scheduled-notification/${notificationId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this._authService.currentUserValue.jwt,
    });

    return { headers: headers };
  }
}
