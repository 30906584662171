import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;

	constructor(
		private http: HttpClient,
		private cookieService: CookieService
	) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	login(username: string, password: string) {
		return this.http.post<any>(`${environment.apiUrl}/api/v2/authentication/signin`, { emailAddress: username, password: password })
			.pipe(map(user => {
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				localStorage.setItem('currentUser', JSON.stringify(user));
				localStorage.setItem('app-auth_token', user.jwt);
				this.currentUserSubject.next(user);

				let today = new Date();
				today.setMonth(today.getMonth() + 6);
				this.cookieService.set('app-auth_token', user.jwt, today);
				return user;
			}));
	}

	logout() {
		return this.http.post<any>(`${environment.apiUrl}/api/v2/authentication/signout`, {})
			.pipe(map(data => {
				localStorage.removeItem('currentUser');
				localStorage.removeItem('app-auth_token');
				this.currentUserSubject.next(null);

				this.cookieService.delete('app-auth_token');
				return data;
			}));
	}

	protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.currentUserValue.jwt,
    });

    return { headers: headers };
  }
}
