import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { first } from 'rxjs/operators';
import { MessageService } from '@app/_services/message.service';
import { ProjectService } from '@app/_services/project.service';

@Component({
  selector: '[gf-message-list]',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnInit {

  loading:Boolean = true;
  filterOpen:Boolean = false;

	constructor(
    private messageService: MessageService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private location: Location
	) { }

  @Input()
  data = [];
  @Input()
  hideActions = false;
  @Input()
  workerList = [];
  @Output()
  tabControl = new EventEmitter<any>();

  showArchived: Boolean = false;
  activeMessages = [];
  activeMessagesOriginal = [];
  archivedMessages = [];
  selectedMessage;

  filterAssigned = [];
  filterFrom = [];
  filterStatus = 0;

  assignToDropdownSettings = {
    idfield: 'workerId',
    textField: 'workerName',
    singleSelection: true,
    allowSearchFilter: true,
    enableCheckAll: false
  };

  searching = false;
  searchingTimeout;
  projectName = '';
  searchResult = [];
  targetProjectId;
  dateStartInput;
  dateEndInput;

  ngOnInit() {
    this.messageService.getMessageList('active').pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.tabControl.next({tabString: 'messageList', tabAttr: 'data', tabAttrValue: data.data});
            this.activeMessagesOriginal = data.data;

            this.route.paramMap.subscribe(params => {
        			if( params.get('messageId') ) {
        				this.showMessage(params.get('messageId'));
        			}
        		});
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
    this.messageService.getMessageList('archived').pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.archivedMessages = data.data;
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  projectSearch(keyword) {
    if( keyword && keyword.length > 2 ) {
      this.searching = true;
      if( this.searchingTimeout ) {
        clearTimeout(this.searchingTimeout);
      }
      this.searchingTimeout = setTimeout(() => {
        this.projectService.searchProject(keyword).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.searchResult = data.data;
            }
            this.searching = false;
          },
          error => {
            console.log(error);
            this.searching = false;
          }
        );
      }, 1500);
    } else {
      clearTimeout(this.searchingTimeout);
      this.searching = false;
    }
  }

  setProject(projectId, projectName) {
    this.projectName = projectName;
    this.targetProjectId = projectId;
    this.searchResult = [];
  }

  addMessage() {
    this.tabControl.next({tabString: 'messageList', tabAttr: 'width', tabAttrValue: '40%'});
    this.tabControl.next({tabString: 'messageList', tabAttr: 'hideActions', tabAttrValue: true});
    // this.tabControl.next({tabString: 'projectDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'messageForm', tabAttr:'visibility', tabAttrValue: 'visible'});
    this.tabControl.next({tabString: 'messageForm', tabAttr:'data', tabAttrValue: ''});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'messageList', tabAttr: 'width', tabAttrValue: '40%'});
      // this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'messageForm', tabAttr:'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'messageForm', tabAttr:'width', tabAttrValue: 'calc(60% - 2rem)'});
    } else {
      this.tabControl.next({tabString: 'messageList', tabAttr: 'left', tabAttrValue: '-100%'});
      // this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: '0'});
      // this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
      this.tabControl.next({tabString: 'messageForm', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'messageForm', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
    if( document.querySelector('.row-project.is-active') ) {
      document.querySelector('.row-project.is-active').classList.remove('is-active');
    }
  }
  showMessage(messageId) {
    this.location.replaceState('/messages/'+ messageId);
    if( document.querySelectorAll('.dropdown-form-wrap.is-active') ) {
      document.querySelectorAll('.dropdown-form-wrap.is-active').forEach((elem) => {
        elem.classList.remove('is-active');
      });
    }
    this.hideActions = true;
    this.selectedMessage = messageId;
    this.tabControl.next({tabString: 'messageList', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'messageDetails', tabAttr: 'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'messageDetails', tabAttr: 'visibility', tabAttrValue: 'visible'});
    this.tabControl.next({tabString: 'messageDetails', tabAttr: 'messageId', tabAttrValue: messageId});
    this.tabControl.next({tabString: 'messageForm', tabAttr: 'visibility', tabAttrValue: 'hidden'});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'messageList', tabAttr: 'width', tabAttrValue: '40%'});
      this.tabControl.next({tabString: 'messageDetails', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'messageDetails', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
    } else {
      this.tabControl.next({tabString: 'messageList', tabAttr: 'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'messageDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'messageDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }

  applyFilter() {
    let toSplice = [];
    this.data = [];
    this.activeMessagesOriginal.forEach((single, index) => {
      if( (this.projectName.length > 0 && this.targetProjectId && single.projectId !== this.targetProjectId) ||
          (this.filterFrom && this.filterFrom.length > 0 && single.createdByWorker.workerId !== this.filterFrom[0].id) ||
          (this.filterStatus != 0 && single.statusId != this.filterStatus) ||
          (this.dateStartInput && new Date(single.createdDate) <= this.dateStartInput) ||
          (this.dateEndInput && new Date(single.createdDate) >= this.dateEndInput) ) {
        toSplice.push(index);
      } else {
        this.data.push(single);
      }
      if( this.filterAssigned.length > 0 ) {
        let bye = true;
        single.workflows.forEach((flow) => {
          if( flow.assignedToWorker && flow.assignedToWorker.workerId == this.filterAssigned[0].id ) {
            bye = false;
          }
        });
        if( bye && toSplice.indexOf(index) < 0 ) {
          toSplice.push(index);
        }
      }
    });
    this.filterOpen = false;
  }
  clearFilter() {
    this.data = this.activeMessagesOriginal;
    this.filterStatus = 0;
    this.projectName = '';
    this.targetProjectId = '';
    this.filterFrom = [];
    this.filterAssigned = [];
    this.dateStartInput = '';
    this.dateEndInput = '';
  }
}
