import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { first } from 'rxjs/operators';
import { ProjectService } from '@app/_services/project.service';
import { PenetrationService } from '@app/_services/penetration.service';

@Component({
  selector: '[gf-project-details]',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, OnChanges {

  loading:Boolean = true;
  sendingReport: Boolean = false;

  @Input()
  projectId;
  @Input()
  tabData;
  @Input()
  data;
  @Input()
  hideActions = false;
  @Input()
  showPenetration = false;
  @Input()
  showLinear = false;
  @Input()
  showDayworks = false;
  @Input()
  hideProjectContacts = false;
  @Output()
  tabControl = new EventEmitter<any>();

  activeTab;
  workerSearch;
  contacts = [];

  collapsedWidth:any = 0;

  dateRange = 1;
  qaDateRange = 1;
  thisMonthStart;
  thisMonthEnd;
  lastMonthStart;
  lastMonthEnd;
  reportTargetEmail = '';
  reportDateTargetEmail = '';
  reportMessage = '';
  reportCreated: Boolean = false;

  closingProject: Boolean = false;
  generatingPS3: Boolean = false;
  PS3generated: Boolean = false;

  filterByQADate: Boolean = false;
  invoicedOnly: Boolean = false;
  includeQAStatus: Boolean = false;
  QAReportTargetEmail = '';
  invoices = [];
  invoiceDate = 0;
  invoiceReportTargetEmail = '';

  projectContacts = [];
  selectedContacts = [];
  contactCountText = '';

  contactDropdownSettings = {
    idfield: 'id',
    textField: 'fullName'
  }

  @Input()
  searchQuery = '';
  @Input()
  searchLimit = 10;
  @Input()
  includeArchived: boolean = false;

	constructor(
    private projectService: ProjectService,
    private penetrationService: PenetrationService,
    private location: Location
	) {
    let tms = new Date();
    if( tms.getDate() >= 16 ) {
      tms.setMonth(tms.getMonth() + 1);
    }
    tms.setDate(16);
    tms.setMonth(tms.getMonth() - 1);
    if( tms.getMonth() < 1 ) {
      tms.setFullYear(tms.getFullYear() - 1);
    }
    this.thisMonthStart = tms;
    let tme = new Date();
    if( tme.getDate() >= 16 ) {
      tme.setMonth(tme.getMonth() + 1);
    }
    tme.setDate(15);
    this.thisMonthEnd = tme;
    let lms = new Date();
    if( lms.getDate() >= 16 ) {
      lms.setMonth(lms.getMonth() + 1);
    }
    lms.setDate(16);
    lms.setMonth(lms.getMonth() - 2);
    if( lms.getMonth() < 1 ) {
      lms.setFullYear(lms.getFullYear() - 1);
    }
    this.lastMonthStart = lms;
    let lme = new Date();
    if( lme.getDate() >= 16 ) {
      lme.setMonth(lme.getMonth() + 1);
    }
    lme.setDate(15);
    lme.setMonth(lme.getMonth() - 1);
    if( lme.getMonth() < 1 ) {
      lme.setFullYear(lme.getFullYear() - 1);
    }
    this.lastMonthEnd = lme;
  }

  ngOnChanges(changes: SimpleChanges) {
    const projectId: SimpleChange = changes.projectId;
    if( projectId && projectId.currentValue ) {
      this.activeTab = '';
      this.closingProject = false;
      if( document.querySelector('.card-project-details .gf-card-body') ) {
        document.querySelector('.card-project-details .gf-card-body').scrollTop = 0;
      }
      this.data = {};
      this.loading = true;
      this.projectService.getProjectById(this.projectId).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.data = data.data;
            }
            this.loading = false;
            if( this.showPenetration ) {
              this.showPenetrationRegister();
            }
            if( this.showLinear ) {
              this.showLinearRegister();
            }
            if( this.showDayworks ) {
              this.showDayworkMarkers();
            }
          },
          error => {
            this.loading = false;
          });
    }
  }

  ngOnInit() {
    let headerElement = <HTMLElement>document.querySelector('.header');
    let cardHeaderWidth = window.innerHeight - headerElement.offsetHeight;
    this.collapsedWidth = 'calc('+ cardHeaderWidth +'px - 2rem)';
  }

  onItemSelect(item: any, group = null) {
    this.selectedContacts.push(item.id);
    this.contactCountText = this.selectedContacts.length + ' selected';
  }
  onItemSelectAll(item: any, group = null) {
    this.selectedContacts = [];
    this.projectContacts.forEach((single) => {
      this.selectedContacts.push(single.id);
    });
    setTimeout(() => {
      this.contactCountText = this.selectedContacts.length + ' selected';
    }, 100);
  }
  onItemDeSelect(item: any, group = null) {
    this.selectedContacts.splice(this.selectedContacts.indexOf(item.id), 1);
    if( this.selectedContacts.length < 1 ) {
      this.contactCountText = '';
    } else {
      this.contactCountText = this.selectedContacts.length + ' selected';
    }
  }
  onItemDeSelectAll(item: any, group = null) {
    this.selectedContacts = [];
    this.contactCountText = '';
  }

  setTab(tabString) {
    if( this.activeTab == tabString ) {
      this.activeTab = '';
    } else {
      this.activeTab = tabString;
    }
  }

  toggleClass(event, className) {
    this.reportCreated = false;
    if( event ) {
      let hasClass = event.target.parentElement.classList.contains(className);
      if(hasClass) {
        event.target.parentElement.classList.remove(className);
      } else {
        event.target.parentElement.classList.add(className);
      }
    }
  }

  editProject() {
    if( !this.loading && this.data && this.data.id ) {
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'projectForm', tabAttr:'visibility', tabAttrValue: 'visible'});
      this.tabControl.next({tabString: 'projectForm', tabAttr:'data', tabAttrValue: this.data});
      if( this.tabData.penetrationRegister.visibility == 'visible' || this.tabData.dayworkMarkers.visibility == 'visible' ) {
        if( window.innerWidth >= 992 ) {
          this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: 'calc(60px + 1rem)'});
          this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc((50% - 30px) - 1.5rem)'});
          this.tabControl.next({tabString: 'projectForm', tabAttr:'left', tabAttrValue: 'calc(60px + 1rem)'});
          this.tabControl.next({tabString: 'projectForm', tabAttr: 'width', tabAttrValue: 'calc((50% - 30px) - 1.5rem)'});
        }
      } else {
        if( window.innerWidth >= 992 ) {
          this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: 'calc(40% + 1rem)'});
          this.tabControl.next({tabString: 'projectDetails', tabAttr:'width', tabAttrValue: 'calc(60% - 2rem)'});
          this.tabControl.next({tabString: 'projectForm', tabAttr:'left', tabAttrValue: 'calc(40% + 1rem)'});
          this.tabControl.next({tabString: 'projectForm', tabAttr:'width', tabAttrValue: 'calc(60% - 2rem)'});
        }
      }
    }
  }

  collapse() {
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: true});
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
    if( this.tabData.penetrationRegister.visibility == 'visible' ) {
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'width', tabAttrValue: 'calc(100% - 120px - 3rem)'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: 'calc(120px + 2rem)'});
    }
    if( this.tabData.linearRegister.visibility == 'visible' ) {
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'width', tabAttrValue: 'calc(100% - 120px - 3rem)'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: 'calc(120px + 2rem)'});
    }
    if( this.tabData.dayworkMarkers.visibility == 'visible' ) {
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'width', tabAttrValue: 'calc(100% - 120px - 3rem)'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: 'calc(120px + 2rem)'});
    }
    if( this.tabData.penetrationRegister.visibility == 'hidden' && this.tabData.penetrationDetails.visibility == 'hidden' && this.tabData.linearRegister.visibility == 'hidden' && this.tabData.linearDetails.visibility == 'hidden' && this.tabData.dayworkMarkers.visibility == 'hidden' ) {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'hideActions', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: 'calc(100% - 60px - 2rem)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: 'calc(100% - 60px - 1rem)'});
    }
  }
  uncollapse() {
    // DAYWORKS
    if( this.tabData.penetrationDetails.visibility == 'hidden' && this.tabData.linearDetails.visibility == 'hidden' ) {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideActions', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: false});
      // this.tabControl.next({tabString: 'projectDetails', tabAttr: 'visibility', tabAttrValue: 'visible'});

      if( this.tabData.penetrationRegister.visibility == 'visible' ) {
        this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: 'calc(60px + 1rem)'});
        this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc((50% - 30px) - 1.5rem)'});
        this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: 'calc(50% + 30px + 0.5rem)'});
        this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'width', tabAttrValue: 'calc(50% - 30px - 1.5rem)'});
      }
      if( this.tabData.linearRegister.visibility == 'visible' ) {
        this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: 'calc(60px + 1rem)'});
        this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc((50% - 30px) - 1.5rem)'});
        this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: 'calc(50% + 30px + 0.5rem)'});
        this.tabControl.next({tabString: 'linearRegister', tabAttr:'width', tabAttrValue: 'calc(50% - 30px - 1.5rem)'});
      }
      if( this.tabData.dayworkMarkers.visibility == 'visible' ) {
        this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: 'calc(60px + 1rem)'});
        this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc((50% - 30px) - 1.5rem)'});
        this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: 'calc(50% + 30px + 0.5rem)'});
        this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'width', tabAttrValue: 'calc(50% - 30px - 1.5rem)'});
      }
      // else {
      //   this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
      //   this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: '100%'});
      //   this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'width', tabAttrValue: '0'});
      //   this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: '100%'});
      //   this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'width', tabAttrValue: '0'});
      //   this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: '100%'});
      //   this.tabControl.next({tabString: 'linearRegister', tabAttr:'width', tabAttrValue: '0'});
      // }
    }
    // if( this.tabData.dayworkMarkers.visibility == 'hidden' && this.tabData.penetrationDetails.visibility == 'hidden' && this.tabData.linearDetails.visibility == 'hidden' ) {
    //   this.tabControl.next({tabString: 'projectList', tabAttr: 'hideActions', tabAttrValue: false});
    //   // this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: '40%'});
    //   // this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
    //   this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
    // }
    if( this.tabData.dayworkMarkers.visibility == 'visible' && (this.tabData.penetrationDetails.visibility == 'visible' || this.tabData.linearDetails.visibility == 'visible') ) {
      if( document.querySelector('.gf-datatable-wrap .is-active') ) {
        document.querySelector('.gf-datatable-wrap .is-active').classList.remove('is-active');
      }
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideActions', tabAttrValue: true});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideProjectContacts', tabAttrValue: false});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr:'left', tabAttrValue: '100%'});
      if( window.innerWidth >= 992 ) {
        this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: false});
        this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: false});
        this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: 'calc(40%)'});
        this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
        this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
      } else {
        this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: '0'});
        this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
      }
    }
  }
  close() {
    if( window.innerWidth < 992 ) {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'left', tabAttrValue: '0'});
    }
    this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'projectList', tabAttr: 'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'projectForm', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'projectDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'visibility', tabAttrValue: 'hidden'});
    if( document.querySelector('.row-project.is-active') ) {
      document.querySelector('.row-project.is-active').classList.remove('is-active');
    }
    if( document.querySelector('.gf-datatable-wrap .is-active') ) {
      document.querySelector('.gf-datatable-wrap .is-active').classList.remove('is-active');
    }
    this.uncollapse();
  }

  showPenetrationRegister() {
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'visibility', tabAttrValue: 'hidden'});

    this.tabControl.next({tabString: 'projectDetails', tabAttr:'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'projectDetails', tabAttr:'hideProjectContacts', tabAttrValue: true});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'visibility', tabAttrValue: 'visible'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'projectId', tabAttrValue: this.data.id});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: true});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'left', tabAttrValue: '0px'});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: 'calc(60px + 1rem)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'width', tabAttrValue: 'calc(50% - 30px - 1.5rem)'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: 'calc(50% + 30px + 0.5rem)'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'width', tabAttrValue: 'calc(50% - 30px - 1.5rem)'});
    } else {
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }
  showLinearRegister() {
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'visibility', tabAttrValue: 'hidden'});

    this.tabControl.next({tabString: 'projectDetails', tabAttr:'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'projectDetails', tabAttr:'hideProjectContacts', tabAttrValue: true});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'visibility', tabAttrValue: 'visible'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'projectId', tabAttrValue: this.data.id});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: true});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'left', tabAttrValue: '0px'});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: 'calc(60px + 1rem)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'width', tabAttrValue: 'calc(50% - 30px - 1.5rem)'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: 'calc(50% + 30px + 0.5rem)'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'width', tabAttrValue: 'calc(50% - 30px - 1.5rem)'});
    } else {
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }

  showDayworkMarkers() {
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});

    this.tabControl.next({tabString: 'projectDetails', tabAttr:'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'projectDetails', tabAttr:'hideProjectContacts', tabAttrValue: true});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'visibility', tabAttrValue: 'visible'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'projectId', tabAttrValue: this.data.id});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: true});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'left', tabAttrValue: '0px'});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: 'calc(60px + 1rem)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'width', tabAttrValue: 'calc(50% - 30px - 1.5rem)'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: 'calc(50% + 30px + 0.5rem)'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'width', tabAttrValue: 'calc(50% - 30px - 1.5rem)'});
    } else {
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }

  showContacts() {
    this.tabControl.next({tabString: 'projectContacts', tabAttr: 'projectId', tabAttrValue: this.projectId});
    this.tabControl.next({tabString: 'projectContacts', tabAttr: 'visible', tabAttrValue: true});
  }

  sendReport() {
    if( !this.sendingReport && this.reportTargetEmail.length > 0 ) {
      this.sendingReport = true;
      this.reportCreated = false;

      let reportData = {
        projectId: this.projectId,
        emailAddress: this.reportTargetEmail
      }

      this.penetrationService.sendPenetrationReport(reportData).pipe(first())
        .subscribe(
          data => {
            this.sendingReport = false;
            this.reportTargetEmail = '';
            this.reportCreated = true;
          },
          error => {
            this.sendingReport = false;
          }
        );
    }
  }
  sendReportWithinDate() {
    if( !this.sendingReport ) {
      this.sendingReport = true;
      this.reportCreated = false;

      let dateStart;
      let dateEnd;
      if( this.dateRange == 1 ) {
        if( this.thisMonthStart )
          dateStart  = formatDate(this.thisMonthStart, 'yyyy-MM-dd', 'en');
        if( this.thisMonthEnd )
          dateEnd = formatDate(this.thisMonthEnd, 'yyyy-MM-dd', 'en');
      }
      if( this.dateRange == 2 ) {
        if( this.lastMonthStart )
          dateStart  = formatDate(this.lastMonthStart, 'yyyy-MM-dd', 'en');
        if( this.lastMonthEnd )
          dateEnd = formatDate(this.lastMonthEnd, 'yyyy-MM-dd', 'en');
      }

      let reportData = {
        projectId: this.projectId,
        startDate: dateStart,
        endDate: dateEnd,
        emailAddress: this.reportDateTargetEmail
      }

      this.penetrationService.sendPenetrationReport(reportData).pipe(first())
        .subscribe(
          data => {
            this.sendingReport = false;
            this.reportDateTargetEmail = '';
            this.reportCreated = true;
          },
          error => {
            this.sendingReport = false;
          }
        );
    }
  }

  sendQAReport() {
    if( !this.sendingReport ) {
      this.sendingReport = true;
      this.reportCreated = false;

      let dateStart;
      let dateEnd;
      if( this.qaDateRange == 1 ) {
        if( this.thisMonthStart )
          dateStart  = formatDate(this.thisMonthStart, 'yyyy-MM-dd', 'en');
        if( this.thisMonthEnd )
          dateEnd = formatDate(this.thisMonthEnd, 'yyyy-MM-dd', 'en');
      }
      if( this.qaDateRange == 2 ) {
        if( this.lastMonthStart )
          dateStart  = formatDate(this.lastMonthStart, 'yyyy-MM-dd', 'en');
        if( this.lastMonthEnd )
          dateEnd = formatDate(this.lastMonthEnd, 'yyyy-MM-dd', 'en');
      }

      let reportData = {
        projectId: this.projectId,
        startDate: dateStart,
        endDate: dateEnd,
        emailAddress: this.QAReportTargetEmail,
        filterByQADate: this.filterByQADate,
        invoicedOnly: this.invoicedOnly,
        includeQAStatus: this.includeQAStatus
      }

      this.penetrationService.sendQAReport(reportData).pipe(first())
        .subscribe(
          data => {
            this.sendingReport = false;
            this.reportCreated = true;
            this.includeQAStatus = false;
            this.invoicedOnly = false;
            this.filterByQADate = false;
            this.QAReportTargetEmail = '';
          },
          error => {
            this.sendingReport = false;
          }
        );
    }
  }

  loadStatus() {
    this.reportMessage = '';
    this.reportCreated = false;
    this.penetrationService.getReportStatus(this.projectId).pipe(first())
      .subscribe(
        data => {
          if( data && data.data && data.data.message ) {
            this.reportMessage = data.data.message;
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getInvoices() {
    this.penetrationService.getInvoices(this.projectId).pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.invoices = data.data;
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  sendInvoiceReport() {
    if( !this.sendingReport && this.invoiceReportTargetEmail.length > 0 && this.invoiceDate != 0 ) {
      this.sendingReport = true;
      this.reportCreated = false;

      let reportData = {
        projectId: this.projectId,
        invoiceId: this.invoiceDate,
        emailAddress: this.invoiceReportTargetEmail
      }

      this.penetrationService.sendPenetrationReport(reportData).pipe(first())
        .subscribe(
          data => {
            this.sendingReport = false;
            this.invoiceReportTargetEmail = '';
            this.invoiceDate = 0;
            this.reportCreated = true;
          },
          error => {
            this.sendingReport = false;
          }
        );
    }
  }

  closeProject() {
    if( this.closingProject ) {
      let contactArray = {
        projectContactIds: this.selectedContacts
      }
      this.projectService.closeOutProject(this.projectId, contactArray).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            this.closingProject = false;
            let originalMessage = this.tabData.projectList.projects.filter((single) => {
              return single.id == this.projectId;
            });
            if( originalMessage && originalMessage.length > 0 ) {
              let originalMessageIndex = this.tabData.projectList.projects.indexOf(originalMessage[0]);
              this.tabData.projectList.projects.splice(originalMessageIndex, 1);
              this.close();
              this.location.replaceState('/project');
            }
          },
          error => {
            console.log(error);
          }
        );
    } else {
      this.closingProject = true;
      this.projectContacts = [];
      this.selectedContacts = [];
      this.contacts = [];
      this.contactCountText = '';
      this.projectService.getProjectContacts(this.projectId).pipe(first())
        .subscribe(
          data => {
            if( data.data ) {
              this.projectContacts = data.data;
            }
            this.loading = false;
          },
          error => {
            console.log(error);
            this.loading = false;
          }
        );
    }
  }

  generatePS3() {
    if( this.generatingPS3 ) {
      let contactArray = {
        projectContactIds: this.selectedContacts
      }
      this.projectService.generatePS3(this.projectId, contactArray).pipe(first())
        .subscribe(
          data => {
            console.log(data);
            this.generatingPS3 = false;
            this.PS3generated = true;
          },
          error => {
            console.log(error);
          }
        );
    } else {
      this.generatingPS3 = true;
      this.projectContacts = [];
      this.selectedContacts = [];
      this.contacts = [];
      this.contactCountText = '';
      this.projectService.getProjectContacts(this.projectId).pipe(first())
        .subscribe(
          data => {
            if( data.data ) {
              this.projectContacts = data.data;
            }
            this.loading = false;
          },
          error => {
            console.log(error);
            this.loading = false;
          }
        );
    }
  }
}
