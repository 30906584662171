import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* material datepicker */
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule } from '@angular/material';

import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { MessagesComponent } from './messages';
import { FormSettingsComponent } from './form-settings';

import { HeaderComponent } from './components/header';
import { ProjectListComponent } from './components/project/project-list';
import { ProjectFormComponent } from './components/project/project-form';
import { ProjectDetailsComponent } from './components/project/project-details';
import { ProjectContactsComponent } from './components/project/project-contacts';
import { PenetrationRegisterComponent } from './components/project/penetration-register';
import { PenetrationDetailsComponent } from './components/project/penetration-details';
import { LinearRegisterComponent } from './components/project/linear-register';
import { LinearDetailsComponent } from './components/project/linear-details';
import { DayworkMarkersComponent } from './components/project/daywork-markers';

import { PS3ConfigComponent } from './components/ps3-config';

import { MessageListComponent } from './components/message/message-list';
import { MessageFormComponent } from './components/message/message-form';
import { MessageDetailsComponent } from './components/message/message-details';

import { FilterPipe } from './filter.pipe';
import { SafeStylePipe } from './safe-style.pipe';
import { CookieService } from 'ngx-cookie-service';

// used to create fake backend
// import { fakeBackendProvider } from './_helpers';

import { CronEditorModule } from 'cron-editor';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    MessagesComponent,
    FormSettingsComponent,
    HeaderComponent,
    ProjectListComponent,
    ProjectFormComponent,
    ProjectDetailsComponent,
    ProjectContactsComponent,
    PenetrationRegisterComponent,
    PenetrationDetailsComponent,
    LinearRegisterComponent,
    LinearDetailsComponent,
    DayworkMarkersComponent,
    MessageListComponent,
    MessageFormComponent,
    MessageDetailsComponent,
    PS3ConfigComponent,
    FilterPipe,
    SafeStylePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSlimScrollModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgMultiSelectDropDownModule.forRoot(),
    CronEditorModule
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
