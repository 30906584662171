import { Component } from '@angular/core';
import { first } from 'rxjs/operators';

// import { User } from '@app/_models';
// import { UserService, AuthenticationService } from '@app/_services';
import { WorkerService } from '@app/_services/worker.service';

@Component({
	templateUrl: 'messages.component.html',
	styleUrls: ['./messages.component.scss']
})
export class MessagesComponent {

	constructor(
		private workerService: WorkerService
	) { }

	workerList = [];

	loading = false;
	tab = {
		messageList: {
			'data': '',
			'visible': true,
			'visibility': 'visible',
			'width': 'calc(100% - 1rem)',
			'left': '0',
			'hideActions': false,
			'isCollapsed': false
		},
		messageForm: {
			'data': '',
			'visible': true,
			'visibility': 'visible',
			'width': 'calc(60% - 2rem)',
			'left': '100%',
			'hideActions': false,
			'isCollapsed': false
		},
		messageDetails: {
			'data': '',
			'messageId': '',
			'selectedWorkflow': '',
			'selectedWorkflowId': '',
			'visible': true,
			'visibility': 'visible',
			'width': 'calc(60% - 2rem)',
			'left': '100%',
			'hideActions': true
		}
	};

	ngOnInit() {
		this.loading = true;

		this.workerService.getWorkerList()
      .pipe(first())
      .subscribe(
        data => {
          if( data.data ) {
            this.workerList = data.data;
            this.workerList.forEach((worker) => {
              worker.workerId = worker.id;
              worker.workerName = worker.name;
            });
          }
        },
        error => {
        });
	}
	updateVisibleTabs(tabInfo) {
		this.tab[tabInfo.tabString][tabInfo.tabAttr] = tabInfo.tabAttrValue;
	}
}
