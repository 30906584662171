import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class PenetrationService {

  constructor(
    private http: HttpClient,
    private _authService: AuthenticationService
  ) {}

  getList(projectId, startDate = null, endDate = null, showUnapproved = null, showApproved = null, showDayworks = null, start = 0, limit = 10) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/register-penetration?projectId=${projectId}&startDate=${startDate}&endDate=${endDate}&showUnapproved=${showUnapproved}&showApproved=${showApproved}&showDayworks=${showDayworks}&start=${start}&limit=${limit}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getPenetration(penetrationId) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/register-penetration/${penetrationId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  approvePenetration(penetrationId) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/register-penetration/${penetrationId}/approve`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  rejectPenetration(penetrationId, rejectMessage) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/register-penetration/${penetrationId}/reject`, {message: rejectMessage}, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  sendPenetrationReport(reportData) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/register-penetration/detail-report`, reportData, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  approveDayworks(dayworksId) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/dayworks-marker/${dayworksId}/approve`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  rejectDayworks(dayworksId, rejectMessage) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/dayworks-marker/${dayworksId}/reject`, {message: rejectMessage}, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  sendQAReport(reportData) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/register-penetration/quality-assurance-report`, reportData, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getReportStatus(projectId) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/register-penetration/report-status?projectId=${projectId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getInvoices(projectId) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/register-penetration/invoices/${projectId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  updateRegisterPenetration(dataId, dataArray) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/register-penetration/${dataId}`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getDayworksMarker(projectId, startDate = null, endDate = null, showUnapproved = null, showApproved = null) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/dayworks-marker?projectId=${projectId}&startDate=${startDate}&endDate=${endDate}&showUnapproved=${showUnapproved}&showApproved=${showApproved}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getRegisterPreparation(dataId) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/register-preparation/${dataId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  uploadImage(dataArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/register-penetration/upload-image`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  deleteImage(imageId) {
    return this.http.delete<any>(`${environment.apiUrl}/api/v2/register-penetration/delete-image/${imageId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this._authService.currentUserValue.jwt,
    });

    return { headers: headers };
  }
}
