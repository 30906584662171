import { Component } from '@angular/core';
import { first } from 'rxjs/operators';

import { FormSettingService } from '@app/_services/form-setting.service';

@Component({
	templateUrl: 'form-settings.component.html',
	styleUrls: ['./form-settings.component.scss']
})
export class FormSettingsComponent {

	constructor(
		private formSettingService: FormSettingService
	) { }

	loading = false;
	submitted = false;
	data;
	lookupData;

	currentForm = "Single T/A";

	serviceTypeSearch = '';
	ceilingSelectionSearch = '';
	productSearch = '';
	holeSizeSearch = '';
	oversizeCavitySearch = '';
	overlaySearch = '';
	constructionSystemSearch = '';
	fireRatingSearch = '';

	singleTATypeOfWorkSearch = '';
	singleTATypeOfWorkInput = '';

	serviceSelectCategory = 0;
	serviceSelectService = 0;
	serviceInput = '';
	productSelectBrand = 0;
	productBrandInput = '';
	productSelectCategory = 0;
	productCategoryInput = '';
	productSelectProduct = 0;
	productNameInput = '';
	productCodeInput = '';
	productSelectUnitSize = 0;
	productUnitSizeInput = '';
	constructionSystemSelectLocation = 0;
	constructionSystemSelectSubstrate = 0;
	csSubstrateInput = '';
	ceilingSelectionInput = '';
	holeSizeInput = '';
	oversizeCavityInput = '';
	overlayInput = '';
	fireRatingInput = '';

	serviceTypeCategory = [];
	serviceTypeSelectCategory = 0;
	serviceTypeNameInput = '';
	serviceTypeCodeInput = '';
	serviceTypeItems = [];
	constructionSystemCategory = [];
	constructionSystem = [];
	constructionSystemItems = [];
	constructionSystemSelectCategory = 0;
	constructionSystemNameInput = '';
	constructionSystemCodeInput = '';	

	productItems = [];
	productBrand = [];
	productCategory = [];
	productUnitSize = [];
	constructionSystemLocation = [];
	constructionSystemName = [];

	serviceTypeHolder = [];
	ceilingSelectionHolder = [];
	productsHolder = [];
	holeSizeHolder = [];
	oversizeCavityHolder = [];
	overlayHolder = [];
	constructionSystemHolder = [];
	fireRatingHolder= [];

	singleTAData = {
		typeOfWorkItems: []
	};
	singleTAHolder = [];

	penetrationEstimateData = {
		materialItems: []
	};
	peMaterialItemHolder = [];
	peMaterialsSearch = '';
	peMaterialsInput = '';

	siteInspectionData = {
		firstAidBoxItems: [],
		firstAiderItems: []
	};
	siFirstAidBoxHolder = [];
	siFirstAiderHolder = [];
	siFirstAidBoxSearch = '';
	siFirstAidBoxInput = '';
	siFirstAiderSearch = '';
	siFirstAiderInput = '';

	surveyChecklistData = {
		typeOfWorkItems: [],
		buildTypeItems: [],
		requiredServiceItems: [],
		scopeOfWorkItems: [],
		fireRatingItems: []
	};
	scTypeOfWorkHolder = [];
	scBuildTypeHolder = [];
	scRequiredServiceHolder = [];
	scScopeOfWorkHolder = [];
	scFireRatingHolder = [];

	scTypeOfWorkSearch = '';
	scTypeOfWorkInput = '';
	scBuildTypeSearch = '';
	scBuildTypeInput = '';
	scRequiredServiceSearch = '';
	scRequiredServiceInput = '';
	scScopeOfWorkSearch = '';
	scScopeOfWorkInput = '';
	scFireRatingSearch = '';
	scFireRatingInput = '';

	ngOnInit() {
		this.loadSingleTA();
		// this.loadSurveyChecklist();
		// this.loadPenetrationEstimate();
		// this.loadSiteInspection();
	}

	toggleForm(formName) {
		if( this.currentForm != formName ) {
			this.currentForm = formName;
			if( formName == 'Single T/A' ) {
				this.loadSingleTA();
			}
			if( formName == 'Survey Checklist' ) {
				this.loadSurveyChecklist();
			}
			if( formName == 'Penetration RM' ) {
				this.loadPenetrationRegisterMarker();
			}
			if( formName == 'Penetration Estimate' ) {
				this.loadPenetrationEstimate();
			}
			if( formName == 'Site Inspection' ) {
				this.loadSiteInspection();
			}
		}
	}

	createFormSetting(optionTypeId, formSettingName = null) {
		if( !this.submitted ) {
			let dataArray;
			if( optionTypeId && formSettingName && formSettingName.length > 0 ) {
				dataArray = {
					optionTypeId: optionTypeId,
					formSettingName: formSettingName
				}
			}
			// if( optionTypeId == 12 && this.serviceSelectCategory !== 0 && this.serviceInput.length > 0 ) {
			// 	dataArray = {
			// 		optionTypeId: optionTypeId,
			// 		categoryId: this.serviceSelectCategory,
			// 		formSettingName: this.serviceInput
			// 	}
			// }
			// if( optionTypeId == 16 && this.constructionSystemSelectLocation !== 0 && this.csSubstrateInput.length > 0 ) {
			// 	dataArray = {
			// 		optionTypeId: optionTypeId,
			// 		locationId: this.constructionSystemSelectLocation,
			// 		formSettingName: this.csSubstrateInput
			// 	}
			// }

			if( optionTypeId == 14 && this.productSelectBrand != 0 && this.productSelectCategory != 0 && this.productNameInput.length > 0 && this.productSelectUnitSize != 0 && this.productCodeInput.length > 0 ) {
				dataArray = {
					productBrandId: this.productSelectBrand,
					productCategoryId: this.productSelectCategory,
					productName: this.productNameInput,
					productUnitId: this.productSelectUnitSize,
					productCode: this.productCodeInput
				}
				this.submitted = true;
				this.formSettingService.createProduct(dataArray).pipe(first())
					.subscribe(
						data => {
							if( data && data.data ) {
								this.productItems.push(data.data);
								this.productsHolder.push(data.data.formSettingName);
								this.resetField(optionTypeId);
								this.submitted = false;
							}
						},
						error => {
							console.log(error);
							this.submitted = false;
						}
					);
			}else if(optionTypeId == 12 && this.serviceTypeSelectCategory != 0 && this.serviceTypeNameInput.length > 0){
				dataArray = {
					serviceTypeCategoryId: this.serviceTypeSelectCategory,
					serviceTypeName: this.serviceTypeNameInput,
					serviceTypeCode: this.serviceTypeCodeInput
				}
				this.submitted = true;
				this.formSettingService.createServiceType(dataArray).pipe(first())
					.subscribe(
						data => {
							if( data && data.data ) {
								this.serviceTypeItems.push(data.data);
								this.serviceTypeHolder.push(data.data.formSettingName);
								this.resetField(optionTypeId);
								this.submitted = false;
							}
						},
						error => {
							console.log(error);
							this.submitted = false;
						}
					);
			}else if(optionTypeId == 16 && this.constructionSystemSelectCategory != 0 && this.constructionSystemNameInput.length > 0){
				dataArray = {
					constructionSystemCategoryId: this.constructionSystemSelectCategory,
					constructionSystemName: this.constructionSystemNameInput,
					constructionSystemCode: this.constructionSystemCodeInput
				}
				this.submitted = true;
				this.formSettingService.createConstructionSystem(dataArray).pipe(first())
					.subscribe(
						data => {
							if( data && data.data ) {
								this.constructionSystemItems.push(data.data);
								this.constructionSystemHolder.push(data.data.formSettingName);
								this.resetField(optionTypeId);
								this.submitted = false;
							}
						},
						error => {
							console.log(error);
							this.submitted = false;
						}
					);
			} else {
				if( dataArray ) {
					this.submitted = true;
					this.formSettingService.createFormSetting(dataArray).pipe(first())
						.subscribe(
							data => {
								if( data && data.data ) {
									if( optionTypeId == 6 ) {
										this.singleTAData.typeOfWorkItems.push(data.data);
										this.singleTAHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 7 ) {
										this.surveyChecklistData.typeOfWorkItems.push(data.data);
										this.scTypeOfWorkHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 8 ) {
										this.surveyChecklistData.buildTypeItems.push(data.data);
										this.scBuildTypeHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 9 ) {
										this.surveyChecklistData.requiredServiceItems.push(data.data);
										this.scRequiredServiceHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 10 ) {
										this.surveyChecklistData.scopeOfWorkItems.push(data.data);
										this.scScopeOfWorkHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 11 ) {
										this.surveyChecklistData.fireRatingItems.push(data.data);
										this.scFireRatingHolder.push(data.data.formSettingName);
									}
									// if( optionTypeId == 12 ) {
									// 	this.data.serviceTypeItems.push(data.data);
									// 	this.serviceTypeHolder.push(data.data.formSettingName);
									// }
									if( optionTypeId == 13 ) {
										this.data.ceilingSelectionItems.push(data.data);
										this.ceilingSelectionHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 18 ) {
										this.penetrationEstimateData.materialItems.push(data.data);
										this.peMaterialItemHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 27 ) {
										this.data.holeSizeItems.push(data.data);
										this.holeSizeHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 28 ) {
										this.data.oversizeCavityItems.push(data.data);
										this.oversizeCavityHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 15 ) {
										this.data.overlayItems.push(data.data);
										this.overlayHolder.push(data.data.formSettingName);
									}
									// if( optionTypeId == 16 ) {
									// 	this.data.constructionSystemItems.push(data.data);
									// 	this.constructionSystemHolder.push(data.data.formSettingName);
									// }
									if( optionTypeId == 17 ) {
										this.data.fireRatingItems.push(data.data);
										this.fireRatingHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 19 ) {
										this.siteInspectionData.firstAidBoxItems.push(data.data);
										this.siFirstAidBoxHolder.push(data.data.formSettingName);
									}
									if( optionTypeId == 20 ) {
										this.siteInspectionData.firstAiderItems.push(data.data);
										this.siFirstAiderHolder.push(data.data.formSettingName);
									}
									this.resetField(optionTypeId);
									this.submitted = false;
								}
							},
							error => {
								console.log(error);
								this.submitted = false;
							}
						);
				}
			}
		}
	}

	resetField(optionTypeId) {
		if( optionTypeId == 6 ) {
			this.singleTATypeOfWorkInput = '';
		}
		if( optionTypeId == 7 ) {
			this.scTypeOfWorkInput = '';
		}
		if( optionTypeId == 8 ) {
			this.scBuildTypeInput = '';
		}
		if( optionTypeId == 9 ) {
			this.scRequiredServiceInput = '';
		}
		if( optionTypeId == 10 ) {
			this.scScopeOfWorkInput = '';
		}
		if( optionTypeId == 11 ) {
			this.scFireRatingInput = '';
		}
		if( optionTypeId == 12 ) {
			this.serviceSelectCategory = 0;
			this.serviceTypeNameInput = '';
			this.serviceTypeCodeInput = '';
		}
		if( optionTypeId == 14 ) {
			this.productBrandInput = '';
			this.productSelectBrand = 0;
			this.productCategoryInput = '';
			this.productSelectCategory = 0;
			this.productNameInput = '';
			this.productCodeInput = '';
			this.productUnitSizeInput = '';
			this.productSelectUnitSize = 0;
		}
		if( optionTypeId == 16 ) {
			this.constructionSystemSelectCategory = 0;
			this.constructionSystemNameInput = '';
			this.constructionSystemCodeInput = '';
			// this.constructionSystemSelectLocation = 0;
			// this.csSubstrateInput = '';
		}
		if( optionTypeId == 27 ) {
			this.holeSizeInput = '';
		}
		if( optionTypeId == 28 ) {
			this.oversizeCavityInput = '';
		}
		if( optionTypeId == 15 ) {
			this.overlayInput = '';
		}
		if( optionTypeId == 13 ) {
			this.ceilingSelectionInput = '';
		}
		if( optionTypeId == 17 ) {
			this.fireRatingInput = '';
		}
		if( optionTypeId == 18 ) {
			this.peMaterialsInput = '';
		}
		if( optionTypeId == 19 ) {
			this.siFirstAidBoxInput = '';
		}
		if( optionTypeId == 20 ) {
			this.siFirstAiderInput = '';
		}
	}

	removeFormSetting(index, formSettingId, formSettingName, group = null) {
		if( group == 'product' ) {
			let targetIndex = this.productsHolder.indexOf(formSettingName);
			this.productItems.splice(targetIndex, 1);
			this.productsHolder.splice(targetIndex, 1);
			let tempArr = [];
			this.productItems.forEach((item) => {
			tempArr.push(item);
			});
			this.productItems = tempArr;

			this.formSettingService.deleteProduct(formSettingId).pipe(first())
				.subscribe(
					data => {
						console.log(data);
					},
					error => {
						console.log(error);
					}
				);
 		} else if(group == 'service'){
			let targetIndex = this.serviceTypeHolder.indexOf(formSettingName);
			this.serviceTypeItems.splice(targetIndex, 1);
			this.serviceTypeHolder.splice(targetIndex, 1);
			let tempArr = [];
			this.serviceTypeItems.forEach((item) => {
			tempArr.push(item);
			});
			this.serviceTypeItems = tempArr;

			this.formSettingService.deleteServiceType(formSettingId).pipe(first())
				.subscribe(
					data => {
						console.log(data);
					},
					error => {
						console.log(error);
					}
				);
		} else if(group == 'construction'){
			let targetIndex = this.constructionSystemHolder.indexOf(formSettingName);
			this.constructionSystemItems.splice(targetIndex, 1);
			this.constructionSystemHolder.splice(targetIndex, 1);
			let tempArr = [];
			this.constructionSystemItems.forEach((item) => {
			tempArr.push(item);
			});
			this.constructionSystemItems = tempArr;

			this.formSettingService.deleteConstructionSystem(formSettingId).pipe(first())
				.subscribe(
					data => {
						console.log(data);
					},
					error => {
						console.log(error);
					}
				);

		} else {
			// if( group == 'service' ) {
			// 	let targetIndex = this.serviceTypeHolder.indexOf(formSettingName);
			// 	this.data.serviceTypeItems.splice(targetIndex, 1);
			// 	this.serviceTypeHolder.splice(targetIndex, 1);
			// 	let tempArr = [];
		    // this.data.serviceTypeItems.forEach((item) => {
		    //   tempArr.push(item);
		    // });
		    // this.data.serviceTypeItems = tempArr;
			// }
			if( group == 'ceiling' ) {
				let targetIndex = this.ceilingSelectionHolder.indexOf(formSettingName);
				this.data.ceilingSelectionItems.splice(targetIndex, 1);
				this.ceilingSelectionHolder.splice(targetIndex, 1);
				let tempArr = [];
		    this.data.ceilingSelectionItems.forEach((item) => {
		      tempArr.push(item);
		    });
		    this.data.ceilingSelectionItems = tempArr;
			}
			if( group == 'holesize' ) {
				let targetIndex = this.holeSizeHolder.indexOf(formSettingName);
				this.data.holeSizeItems.splice(targetIndex, 1);
				this.holeSizeHolder.splice(targetIndex, 1);
				let tempArr = [];
		    this.data.holeSizeItems.forEach((item) => {
		      tempArr.push(item);
		    });
		    this.data.holeSizeItems = tempArr;
			}
			if( group == 'oversizecavity' ) {
				let targetIndex = this.oversizeCavityHolder.indexOf(formSettingName);
				this.data.oversizeCavityItems.splice(targetIndex, 1);
				this.oversizeCavityHolder.splice(targetIndex, 1);
				let tempArr = [];
		    this.data.oversizeCavityItems.forEach((item) => {
		      tempArr.push(item);
		    });
		    this.data.oversizeCavityItems = tempArr;
			}
			if( group == 'overlay' ) {
				let targetIndex = this.overlayHolder.indexOf(formSettingName);
				this.data.overlayItems.splice(targetIndex, 1);
				this.overlayHolder.splice(targetIndex, 1);
				let tempArr = [];
		    this.data.overlayItems.forEach((item) => {
		      tempArr.push(item);
		    });
		    this.data.overlayItems = tempArr;
			}
			// if( group == 'construction' ) {
			// 	let targetIndex = this.constructionSystemHolder.indexOf(formSettingName);
			// 	this.data.constructionSystemItems.splice(targetIndex, 1);
			// 	this.constructionSystemHolder.splice(targetIndex, 1);
			// 	let tempArr = [];
		    // this.data.constructionSystemItems.forEach((item) => {
		    //   tempArr.push(item);
		    // });
		    // this.data.constructionSystemItems = tempArr;
			// }
			if( group == 'firerating' ) {
				let targetIndex = this.fireRatingHolder.indexOf(formSettingName);
				this.data.fireRatingItems.splice(targetIndex, 1);
				this.fireRatingHolder.splice(targetIndex, 1);
				let tempArr = [];
		    this.data.fireRatingItems.forEach((item) => {
		      tempArr.push(item);
		    });
		    this.data.fireRatingItems = tempArr;
			}
			if( group == 'singleTATypeOfWork' ) {
				let targetIndex = this.singleTAHolder.indexOf(formSettingName);
				this.singleTAData.typeOfWorkItems.splice(targetIndex, 1);
				this.singleTAHolder.splice(targetIndex, 1);
				let tempArr = [];
				this.singleTAData.typeOfWorkItems.forEach((item) => {
					tempArr.push(item);
				});
				this.singleTAData.typeOfWorkItems = tempArr;
			}
			if( group == 'scTypeOfWork' ) {
				let targetIndex = this.scTypeOfWorkHolder.indexOf(formSettingName);
				this.surveyChecklistData.typeOfWorkItems.splice(targetIndex, 1);
				this.scTypeOfWorkHolder.splice(targetIndex, 1);
				let tempArr = [];
				this.surveyChecklistData.typeOfWorkItems.forEach((item) => {
					tempArr.push(item);
				});
				this.surveyChecklistData.typeOfWorkItems = tempArr;
			}
			if( group == 'scBuildType' ) {
				let targetIndex = this.scBuildTypeHolder.indexOf(formSettingName);
				this.surveyChecklistData.buildTypeItems.splice(targetIndex, 1);
				this.scBuildTypeHolder.splice(targetIndex, 1);
				let tempArr = [];
				this.surveyChecklistData.buildTypeItems.forEach((item) => {
					tempArr.push(item);
				});
				this.surveyChecklistData.buildTypeItems = tempArr;
			}
			if( group == 'scRequiredService' ) {
				let targetIndex = this.scRequiredServiceHolder.indexOf(formSettingName);
				this.surveyChecklistData.requiredServiceItems.splice(targetIndex, 1);
				this.scRequiredServiceHolder.splice(targetIndex, 1);
				let tempArr = [];
				this.surveyChecklistData.requiredServiceItems.forEach((item) => {
					tempArr.push(item);
				});
				this.surveyChecklistData.requiredServiceItems = tempArr;
			}
			if( group == 'scScopeOfWork' ) {
				let targetIndex = this.scScopeOfWorkHolder.indexOf(formSettingName);
				this.surveyChecklistData.scopeOfWorkItems.splice(targetIndex, 1);
				this.scScopeOfWorkHolder.splice(targetIndex, 1);
				let tempArr = [];
				this.surveyChecklistData.scopeOfWorkItems.forEach((item) => {
					tempArr.push(item);
				});
				this.surveyChecklistData.scopeOfWorkItems = tempArr;
			}
			if( group == 'scFireRating' ) {
				let targetIndex = this.scFireRatingHolder.indexOf(formSettingName);
				this.surveyChecklistData.fireRatingItems.splice(targetIndex, 1);
				this.scFireRatingHolder.splice(targetIndex, 1);
				let tempArr = [];
				this.surveyChecklistData.fireRatingItems.forEach((item) => {
					tempArr.push(item);
				});
				this.surveyChecklistData.fireRatingItems = tempArr;
			}
			if( group == 'peMaterialItem' ) {
				let targetIndex = this.peMaterialItemHolder.indexOf(formSettingName);
				this.penetrationEstimateData.materialItems.splice(targetIndex, 1);
				this.peMaterialItemHolder.splice(targetIndex, 1);
				let tempArr = [];
				this.penetrationEstimateData.materialItems.forEach((item) => {
					tempArr.push(item);
				});
				this.penetrationEstimateData.materialItems = tempArr;
			}
			if( group == 'siFirstAidBox' ) {
				let targetIndex = this.siFirstAidBoxHolder.indexOf(formSettingName);
				this.siteInspectionData.firstAidBoxItems.splice(targetIndex, 1);
				this.siFirstAidBoxHolder.splice(targetIndex, 1);
				let tempArr = [];
				this.siteInspectionData.firstAidBoxItems.forEach((item) => {
					tempArr.push(item);
				});
				this.siteInspectionData.firstAidBoxItems = tempArr;
			}
			if( group == 'siFirstAider' ) {
				let targetIndex = this.siFirstAiderHolder.indexOf(formSettingName);
				this.siteInspectionData.firstAiderItems.splice(targetIndex, 1);
				this.siFirstAiderHolder.splice(targetIndex, 1);
				let tempArr = [];
				this.siteInspectionData.firstAiderItems.forEach((item) => {
					tempArr.push(item);
				});
				this.siteInspectionData.firstAiderItems = tempArr;
			}

			this.formSettingService.deleteFormSetting(formSettingId).pipe(first())
				.subscribe(
					data => {
						console.log(data);
					},
					error => {
						console.log(error);
					}
				);
		}
	}

	loadSingleTA() {
		this.loading = true;
		this.formSettingService.getSingleTA().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.singleTAData = data.data;
						if( data.data.typeOfWorkItems ) {
							data.data.typeOfWorkItems.forEach((item) => {
								this.singleTAHolder.push(item.formSettingName);
							});
						}
					}
					this.loading = false;
				},
				error => {
					console.log(error);
					this.loading = false;
				}
			);
	}

	loadSurveyChecklist() {
		this.loading = true;
		this.formSettingService.getSurveyChecklist().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.surveyChecklistData = data.data;
						if( data.data.typeOfWorkItems ) {
							data.data.typeOfWorkItems.forEach((item) => {
								this.scTypeOfWorkHolder.push(item.formSettingName);
							});
						}
						if( data.data.buildTypeItems ) {
							data.data.buildTypeItems.forEach((item) => {
								this.scBuildTypeHolder.push(item.formSettingName);
							});
						}
						if( data.data.requiredServiceItems ) {
							data.data.requiredServiceItems.forEach((item) => {
								this.scRequiredServiceHolder.push(item.formSettingName);
							});
						}
						if( data.data.scopeOfWorkItems ) {
							data.data.scopeOfWorkItems.forEach((item) => {
								this.scScopeOfWorkHolder.push(item.formSettingName);
							});
						}
						if( data.data.fireRatingItems ) {
							data.data.fireRatingItems.forEach((item) => {
								this.scFireRatingHolder.push(item.formSettingName);
							});
						}
					}
					this.loading = false;
				},
				error => {
					console.log(error);
					this.loading = false;
				}
			);
	}

	loadPenetrationRegisterMarker() {
		this.loading = true;
		this.formSettingService.getPenetrationRegisterMarker().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.data = data.data;
						// if( data.data.serviceTypeItems ) {
						// 	data.data.serviceTypeItems.forEach((item) => {
						// 		this.serviceTypeHolder.push(item.formSettingName);
						// 	});
						// }
						if( data.data.ceilingSelectionItems ) {
							data.data.ceilingSelectionItems.forEach((item) => {
								this.ceilingSelectionHolder.push(item.formSettingName);
							});
						}
						if( data.data.holeSizeItems ) {
							data.data.holeSizeItems.forEach((item) => {
								this.holeSizeHolder.push(item.formSettingName);
							});
						}
						if( data.data.oversizeCavityItems ) {
							data.data.oversizeCavityItems.forEach((item) => {
								this.oversizeCavityHolder.push(item.formSettingName);
							});
						}
						if( data.data.overlayItems ) {
							data.data.overlayItems.forEach((item) => {
								this.overlayHolder.push(item.formSettingName);
							});
						}
						// if( data.data.constructionSystemItems ) {
						// 	data.data.constructionSystemItems.forEach((item) => {
						// 		this.constructionSystemHolder.push(item.formSettingName);
						// 	});
						// }
						if( data.data.fireRatingItems ) {
							data.data.fireRatingItems.forEach((item) => {
								this.fireRatingHolder.push(item.formSettingName);
							});
						}
					}
					this.loading = false;
				},
				error => {
					console.log(error);
					this.loading = false;
				}
			);

		this.formSettingService.getServiceTypeCategory().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.serviceTypeCategory = data.data;
					}
				},
				error => {
					console.log(error);
				}
			);			

		this.formSettingService.getServiceType().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.serviceTypeItems = data.data;
						data.data.forEach((item) => {
						 	this.serviceTypeHolder.push(item.serviceTypeName);
						});
					}
				},
				error => {
					console.log(error);
				}
			);

		this.formSettingService.getConstructionSystemCategory().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.constructionSystemCategory = data.data;
					}
				},
				error => {
					console.log(error);
				}
			);	

		this.formSettingService.getConstructionSystem().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.constructionSystemItems = data.data;
						data.data.forEach((item) => {
						 	this.constructionSystemHolder.push(item.constructionSystemName);
						});
					}
				},
				error => {
					console.log(error);
				}
			);

		this.formSettingService.getProduct().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.productItems = data.data;
						data.data.forEach((item) => {
							this.productsHolder.push(item.productName);
						});
					}
				}
			);
		this.formSettingService.getProductBrand().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.productBrand = data.data;
					}
				},
				error => {
					console.log(error);
				}
			);
		this.formSettingService.getProductCategory().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.productCategory = data.data;
					}
				},
				error => {
					console.log(error);
				}
			);
		this.formSettingService.getProductUnit().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.productUnitSize = data.data;
					}
				},
				error => {
					console.log(error);
				}
			);
		// this.formSettingService.getLookupData().pipe(first())
		// 	.subscribe(
		// 		data => {
		// 			if( data && data.data ) {
		// 				data.data.forEach((item) => {
		// 					if( item.lookupTypeName == 'ServiceTypeService' ) {
		// 						this.serviceTypeService = item.items;
		// 					}
		// 					if( item.lookupTypeName == 'ConstructionSystemLocation' ) {
		// 						this.constructionSystemLocation = item.items;
		// 					}
		// 					if( item.lookupTypeName == 'constructionSystemName' ) {
		// 						this.constructionSystemName = item.items;
		// 					}
		// 				});
		// 			}
		// 		},
		// 		error => {
		// 			console.log(error);
		// 		}
		// 	);
	}

	loadPenetrationEstimate() {
		this.loading = true;
		this.formSettingService.getPenetrationEstimateMarker().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.penetrationEstimateData = data.data;
						if( data.data.materialItems ) {
							data.data.materialItems.forEach((item) => {
								this.peMaterialItemHolder.push(item.formSettingName);
							});
						}
					}
					this.loading = false;
				},
				error => {
					console.log(error);
					this.loading = false;
				}
			);
	}

	loadSiteInspection() {
		this.loading = true;
		this.formSettingService.getSiteInspection().pipe(first())
			.subscribe(
				data => {
					if( data && data.data ) {
						this.siteInspectionData = data.data;
						if( data.data.firstAidBoxItems ) {
							data.data.firstAidBoxItems.forEach((item) => {
								this.siFirstAidBoxHolder.push(item.formSettingName);
							});
						}
						if( data.data.firstAiderItems ) {
							data.data.firstAiderItems.forEach((item) => {
								this.siFirstAiderHolder.push(item.formSettingName);
							});
						}
					}
					this.loading = false;
				},
				error => {
					console.log(error);
					this.loading = false;
				}
			);
	}
}
