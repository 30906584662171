import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

// import { User } from '@app/_models';
// import { UserService, AuthenticationService } from '@app/_services';

@Component({
	templateUrl: 'home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	constructor(
		private route: ActivatedRoute,
		private router: Router
	) { }

	loading = false;
	selectedProject;
	tab = {
		projectList: {
			'projects': {},
			'searchQuery': '',
			'searchLimit': 100,
			'includeArchived': false,
			'selectedProject': '',
			'visible': true,
			'visibility': 'visible',
			'width': 'calc(100% - 1rem)',
			'left': '0',
			'hideActions': false,
			'isCollapsed': false
		},
		projectDetails: {
			'data': '',
			'searchQuery': '',
			'searchLimit': 10,
			'includeArchived': false,
			'projectId': '',
			'visible': true,
			'visibility': 'visible',
			'width': 'calc(60% - 2rem)',
			'left': '100%',
			'hideActions': true,
			'hideProjectContacts': false,
			'isCollapsed': false,
			'showPenetration': false,
			'showLinear': false,
			'showDayworks': false,
		},
		projectForm: {
			'data': '',
			'searchQuery': '',
			'searchLimit': 10,
			'includeArchived': false,
			'visible': true,
			'visibility': 'visible',
			'width': 'calc(60% - 2rem)',
			'left': '100%',
			'hideActions': false,
			'isCollapsed': false
		},
		projectContacts: {
			'data': '',
			'searchQuery': '',
			'searchLimit': 10,
			'includeArchived': false,
			'projectId': '',
			'visible': false
		},
		penetrationRegister: {
			'data': '',
			'searchQuery': '',
			'searchLimit': 50,
			'includeArchived': false,
			'projectId': '',
			'selectedPenetrationIndex': '',
			'visible': true,
			'visibility': 'hidden',
			'width': 'calc(60% - 2rem)',
			'left': '100%',
			'hideActions': true,
			'isCollapsed': false
		},
		penetrationDetails: {
			'data': '',
			'searchQuery': '',
			'searchLimit': 10,
			'includeArchived': false,
			'penetrationId': '',
			'selectedPenetrationIndex': '',
			'visible': true,
			'visibility': 'hidden',
			'width': 'calc(60% - 2rem)',
			'left': '100%',
			'hideActions': true,
			'isCollapsed': false
		},
		linearRegister: {
			'data': '',
			'searchQuery': '',
			'searchLimit': 10,
			'includeArchived': false,
			'projectId': '',
			'selectedLinearIndex': '',
			'visible': true,
			'visibility': 'hidden',
			'width': 'calc(60% - 2rem)',
			'left': '100%',
			'hideActions': true,
			'isCollapsed': false
		},
		linearDetails: {
			'data': '',
			'searchQuery': '',
			'searchLimit': 10,
			'includeArchived': false,
			'linearId': '',
			'selectedLinearIndex': '',
			'visible': true,
			'visibility': 'hidden',
			'width': 'calc(60% - 2rem)',
			'left': '100%',
			'hideActions': true,
			'isCollapsed': false
		},
		dayworkMarkers: {
			'data': '',
			'searchQuery': '',
			'searchLimit': 10,
			'includeArchived': false,
			'projectId': '',
			'selectedPenetrationIndex': '',
			'visible': true,
			'visibility': 'hidden',
			'width': 'calc(60% - 2rem)',
			'left': '100%',
			'hideActions': true,
			'isCollapsed': false
		}
	};

	ngOnInit() {
		this.loading = true;

		this.route.paramMap.subscribe(params => {
			if( params.get('searchQuery') ) {
				this.tab.projectList.searchQuery = params.get('searchQuery').trim();
				this.tab.projectList.searchLimit = parseInt(params.get('searchLimit'));
				this.tab.projectList.includeArchived = params.get('includeArchived') === "false" ? false : true;
			}
			if( params.get('projectId') ) {
				this.tab.projectList.selectedProject = params.get('projectId');

				if( params.get('datePeriod') ) {
					this.tab.projectDetails.showPenetration = true;
				}
				if( this.router.url.includes('linear-register') ) {
					this.tab.projectDetails.showLinear = true;
				}
				if( this.router.url.includes('dayworks-marker') ) {
					this.tab.projectDetails.showDayworks = true;
				}
			}
		});
	}
	updateVisibleTabs(tabInfo) {
		this.tab[tabInfo.tabString][tabInfo.tabAttr] = tabInfo.tabAttrValue;
	}
}
