import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class MessageService {

  constructor(
    private http: HttpClient,
    private _authService: AuthenticationService
  ) {}

  getMessageList(status = null, read = null) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/message?status=${status}&read=${read}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getUnreadMessageCount() {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/message/unread`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  createMessage(messageArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/message`, messageArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  createWorkflow(messageId, messageArray) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/message/${messageId}/workflow`, messageArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  getMessageDetail(messageId) {
    return this.http.get<any>(`${environment.apiUrl}/api/v2/message/${messageId}`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  uploadAttachment(attachment) {
    return this.http.post<any>(`${environment.apiUrl}/api/v2/message/attachment`, attachment,
      {
        reportProgress: true,
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this._authService.currentUserValue.jwt
        })
      })
			.pipe(map(response => {
				return response;
			}));
  }

  approveMessage(messageId) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/message/${messageId}/approve`, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }
  rejectMessage(messageId, dataArray) {
    return this.http.put<any>(`${environment.apiUrl}/api/v2/message/${messageId}/reject`, dataArray, this.getRequestHeaders())
			.pipe(map(response => {
				return response;
			}));
  }

  protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this._authService.currentUserValue.jwt,
    });

    return { headers: headers };
  }
}
