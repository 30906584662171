import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatDate, Location } from '@angular/common';
import { PenetrationService } from '@app/_services/penetration.service';

import { first } from 'rxjs/operators';

@Component({
  selector: '[gf-penetration-register]',
  templateUrl: './penetration-register.component.html',
  styleUrls: ['./penetration-register.component.scss']
})
export class PenetrationRegisterComponent implements OnInit, OnChanges {

  loading: Boolean = false;

  showUnapproved: Boolean = true;
  showApproved: Boolean = true;
  showDayworks: Boolean = false;

  dateRange = 1;
  dateStartInput;
  dateEndInput;
  thisMonthStart;
  thisMonthEnd;
  lastMonthStart;
  lastMonthEnd;

  @Input()
  projectId;
  @Input()
  selectedPenetrationIndex;
  @Input()
  tabData;
  @Input()
  data;
  @Input()
  hideActions = true;
  @Output()
  tabControl = new EventEmitter<any>();

  locationString = '';
  penetrationIndex: number;

  penetrations = [];
  selectedPenetration;
  checkedPenetration = [];
  collapsedWidth = '';
  bulkCheck;
  toggleDayworks = 1;

  @Input()
  searchQuery = '';
  @Input()
  searchLimit = 50;
  @Input()
  includeArchived: boolean = false;

  @Input()
  penetrationSearchLimit = 50;
  @Input()
  currentPage = 1;
  disableNextPage: boolean = false;

	constructor(
    private penetrationService: PenetrationService,
    private route: ActivatedRoute,
    private location: Location
	) {
    let tms = new Date();
    if( tms.getDate() >= 16 ) {
      tms.setMonth(tms.getMonth() + 1);
    }
    tms.setDate(16);
    tms.setMonth(tms.getMonth() - 1);
    if( tms.getMonth() < 1 ) {
      tms.setFullYear(tms.getFullYear() - 1);
    }
    this.thisMonthStart = tms;
    let tme = new Date();
    if( tme.getDate() >= 16 ) {
      tme.setMonth(tme.getMonth() + 1);
    }
    tme.setDate(15);
    this.thisMonthEnd = tme;
    let lms = new Date();
    if( lms.getDate() >= 16 ) {
      lms.setMonth(lms.getMonth() + 1);
    }
    lms.setDate(16);
    lms.setMonth(lms.getMonth() - 2);
    if( lms.getMonth() < 1 ) {
      lms.setFullYear(lms.getFullYear() - 1);
    }
    this.lastMonthStart = lms;
    let lme = new Date();
    if( lme.getDate() >= 16 ) {
      lme.setMonth(lme.getMonth() + 1);
    }
    lme.setDate(15);
    lme.setMonth(lme.getMonth() - 1);
    if( lme.getMonth() < 1 ) {
      lme.setFullYear(lme.getFullYear() - 1);
    }
    this.lastMonthEnd = lme;

    this.route.paramMap.subscribe(params => {
      if( params.get('projectId') ) {
        this.projectId = params.get('projectId');
        if( params.get('approval') ) {
          if( params.get('approval') == 'approved' ) {
            this.showUnapproved = false;
            this.showApproved = true;
          } else if( params.get('approval') == 'unapproved' ) {
            this.showUnapproved = true;
            this.showApproved = false;
          } else {
            this.showUnapproved = true;
            this.showApproved = true;
          }
        }
  			if( params.get('datePeriod') ) {
          if( params.get('datePeriod') == 'last-month' ) {
            this.dateRange = 2;
          } else if( params.get('datePeriod') == 'custom' ) {
            this.dateRange = 0;
          } else {
            this.dateRange = 1;
          }
  			}
  			if( params.get('dateStart') && params.get('dateStart') != 'null' ) {
  				this.dateStartInput = params.get('dateStart');
  			}
  			if( params.get('dateEnd') && params.get('dateEnd') != 'null' ) {
  				this.dateEndInput = params.get('dateEnd');
  			}
  			if( params.get('penetrationIndex') ) {
          this.penetrationIndex = parseInt(params.get('penetrationIndex'));
  			}
      }
		});
  }

  ngOnChanges(changes: SimpleChanges) {
    const projectId: SimpleChange = changes.projectId;
    const selectedPenetrationIndex: SimpleChange = changes.selectedPenetrationIndex;
    if( projectId && projectId.currentValue ) {
      this.penetrations = [];
      this.getPenetrationList();
    }
    if( selectedPenetrationIndex && selectedPenetrationIndex.currentValue > 0 && (selectedPenetrationIndex.currentValue != selectedPenetrationIndex.previousValue) ) {
      if( this.penetrations[selectedPenetrationIndex.currentValue] ) {
        this.selectPenetration(selectedPenetrationIndex.currentValue, this.penetrations[selectedPenetrationIndex.currentValue]);
      }
    }
  }

  ngOnInit() {
    let headerElement = <HTMLElement>document.querySelector('.header');
    let cardHeaderWidth = window.innerHeight - headerElement.offsetHeight;
    this.collapsedWidth = 'calc('+ cardHeaderWidth +'px - 2rem)';
  }

  customRange() {
    if( this.dateStartInput || this.dateEndInput ) {
      this.dateRange = 0;
    }
  }
  presetRange() {
    this.dateStartInput = '';
    this.dateEndInput = '';
  }
  generateReport() {
    if( !this.loading ) {
      this.loading = true;
      let reportData = {
        projectId: this.projectId,
        ids: this.checkedPenetration
      }
      this.penetrationService.sendPenetrationReport(reportData).pipe(first())
        .subscribe(
          data => {
            this.checkedPenetration = [];
            this.bulkCheck = false;
            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        );
    }
  }

  resetPage() {
    this.currentPage = 1;
    this.disableNextPage = false;
  }
  loadPrev() {
    if( this.currentPage > 1 ) {
      this.currentPage--;
      this.getPenetrationList();
    }
  }
  loadNext() {
    if( !this.disableNextPage ) {
      this.currentPage++;
      this.getPenetrationList();
    }
  }

  getPenetrationList() {
    if( !this.loading ) {
      this.loading = true;

      let dateStart;
      let dateEnd;
      this.route.paramMap.subscribe(params => {
        this.locationString = '/project/'+ this.searchQuery +' /'+ this.searchLimit +'/'+ this.includeArchived +'/'+ this.projectId +'/penetration-register/';
      });

      if( this.showUnapproved && !this.showApproved ) {
        this.locationString += 'unapproved/';
      } else if( !this.showUnapproved && this.showApproved ) {
        this.locationString += 'approved/';
      } else {
        this.locationString += 'all/';
      }
      if( this.dateRange == 0 ) {
        this.locationString += 'custom/';
        if( this.dateStartInput ) {
          dateStart  = formatDate(this.dateStartInput, 'yyyy-MM-dd', 'en');
          this.locationString += dateStart +'/';
        } else {
          this.locationString += 'null/';
        }
        if( this.dateEndInput ) {
          dateEnd = formatDate(this.dateEndInput, 'yyyy-MM-dd', 'en');
          this.locationString += dateEnd;
        } else {
          this.locationString += 'null';
        }
      }
      if( this.dateRange == 1 ) {
        this.locationString += 'this-month';
        if( this.thisMonthStart )
          dateStart  = formatDate(this.thisMonthStart, 'yyyy-MM-dd', 'en');
        if( this.thisMonthEnd )
          dateEnd = formatDate(this.thisMonthEnd, 'yyyy-MM-dd', 'en');
      }
      if( this.dateRange == 2 ) {
        this.locationString += 'last-month';
        if( this.lastMonthStart )
          dateStart  = formatDate(this.lastMonthStart, 'yyyy-MM-dd', 'en');
        if( this.lastMonthEnd )
          dateEnd = formatDate(this.lastMonthEnd, 'yyyy-MM-dd', 'en');
      }

      if( this.toggleDayworks == 1 ) {
        this.showDayworks = false;
      } else {
        this.showDayworks = true;
      }

      this.location.replaceState(this.locationString);

      this.penetrationService.getList(this.projectId, dateStart, dateEnd, this.showUnapproved, this.showApproved, this.showDayworks, (this.currentPage - 1) * this.penetrationSearchLimit, this.penetrationSearchLimit).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.penetrations = data.data;

              if( this.penetrationIndex && data.data[this.penetrationIndex - 1] ) {
                this.selectPenetration(this.penetrationIndex - 1, data.data[this.penetrationIndex - 1]);
              }
              this.penetrationIndex = null;

              if( data.data.length < this.penetrationSearchLimit ) {
                this.disableNextPage = true;
              } else {
                this.disableNextPage = false;
              }
            }
            this.loading = false;
          },
          error => {
            this.loading = false;
          });
    }
  }
  selectPenetration(index, penetration) {
    if( (this.selectedPenetration && this.selectedPenetration.id !== penetration.id) || !this.selectedPenetration ) {
      this.selectedPenetration = penetration;
      this.selectedPenetrationIndex = index;
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'penetrationId', tabAttrValue: penetration.id});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'data', tabAttrValue: {number: penetration.number}});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'selectedPenetrationIndex', tabAttrValue: index});
      this.showPenetrationDetail();

      this.penetrationService.getPenetration(penetration.id).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'data', tabAttrValue: data.data});
            }
          },
          error => {
          });

      this.location.replaceState(this.locationString + '/' + parseInt(index + 1));
    }
  }

  bulkSelectToggle() {
    if( this.bulkCheck ) {
      this.penetrations.forEach(penetration => {
        if( !this.checkedPenetration.includes(penetration.id) ) {
          this.checkedPenetration.push(penetration.id);
        }
      });
    } else {
      this.checkedPenetration = [];
    }
  }
  toggleCheckedPenetration(penetrationId) {
    if( this.checkedPenetration.includes(penetrationId) ) {
      this.checkedPenetration.splice(this.checkedPenetration.indexOf(penetrationId), 1);
    } else {
      this.checkedPenetration.push(penetrationId);
    }
    if( this.checkedPenetration.length == 0 ) {
      this.bulkCheck = false;
    }
  }

  showPenetrationDetail() {
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'left', tabAttrValue: 'calc(120px + 2rem)'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});

    this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'visibility', tabAttrValue: 'visible'});

    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: true});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'left', tabAttrValue: 'calc(120px + 2rem)'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'left', tabAttrValue: 'calc(50% + 60px + 3rem)'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'width', tabAttrValue: 'calc(50% - 120px - 0.25rem)'});
    } else {
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }

  collapse() {
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'isCollapsed', tabAttrValue: true});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'left', tabAttrValue: 'calc(180px + 3rem)'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'width', tabAttrValue: 'calc((100% - 180px) - 4rem)'});
  }
  uncollapse() {
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'hideActions', tabAttrValue: false});
    if( this.tabData.penetrationDetails.visibility == 'visible' ) {
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'left', tabAttrValue: 'calc(50% + 60px + 3rem)'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'width', tabAttrValue: 'calc(50% - 120px - 0.25rem)'});
    }
  }

  close() {
    if( document.querySelector('.gf-datatable-wrap .is-active') ) {
      document.querySelector('.gf-datatable-wrap .is-active').classList.remove('is-active');
    }
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideProjectContacts', tabAttrValue: false});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'left', tabAttrValue: '100%'});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: 'calc(40%)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
    } else {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }
}
