import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { FormSettingService } from '@app/_services/form-setting.service';
import { PenetrationService } from '@app/_services/penetration.service';
import { AuthenticationService } from '@app/_services';
import { JwtHelperService } from "@auth0/angular-jwt";
import { WorkerService } from '@app/_services/worker.service';

import { first } from 'rxjs/operators';

@Component({
  selector: '[gf-penetration-details]',
  templateUrl: './penetration-details.component.html',
  styleUrls: ['./penetration-details.component.scss']
})
export class PenetrationDetailsComponent implements OnInit, OnChanges {

  loading: Boolean = true;
  isEditing: Boolean = false;
  submitted: Boolean = false;
  rejecting: Boolean = false;

	constructor(
    private penetrationService: PenetrationService,
    private formSettingService: FormSettingService,
    private authenticationService: AuthenticationService,
    private workerService: WorkerService
	) { }

  @Input()
  penetrationId;
  @Input()
  selectedPenetrationIndex;
  @Input()
  data;
  @Input()
  hideActions = false;
  @Output()
  tabControl = new EventEmitter<any>();

  canApprove: Boolean = false;
  canReject: Boolean = false;
  canEdit: Boolean = false;

  workerList = [];
  selectedInstallers = [];
  selectedInstallersHolder = [];
  installerCountText = '';

  oversizeCavityItems = [];
  serviceTypeItems = [];
  overlayItems = [];
  ceilingSelectionItems = [];
  constructionSystemItems = [];
  fireRatingItems = [];
  productItems = [];
  holeSizeItems = [];

  selectedProject = '';
  ceilingSelection = '';
  constructionSystem = '';
  fireRating = '';
  overlay = '';
  oversizeCavitySize = '';
  penetrationHoleSize = '';
  serviceType = '';
  rejectMessage = '';

  fileInput;

  selectOversizeCavity = [];
  selectOversizeCavityOrig = [];
  selectServiceType = [];
  selectServiceTypeOrig = [];
  selectOverlay = [];
  selectOverlayOrig = [];
  selectHoleSize = [];
  selectHoleSizeOrig = [];
  selectConstructionSystem = [];
  selectConstructionSystemOrig = [];
  selectCeilingSelection = [];
  selectCeilingSelectionOrig = [];
  selectFireRating = [];
  selectFireRatingOrig = [];
  selectProduct = [];
  selectProductOrig = [];

  singleDropdownSetting = {
    idField: 'formSettingId',
    textField: 'formSettingName',
    singleSelection: true,
    allowSearchFilter: true,
    enableCheckAll: false
  }
  multiDropdownSetting = {
    idField: 'formSettingId',
    textField: 'formSettingName',
    singleSelection: false,
    allowSearchFilter: true,
    enableCheckAll: false
  }
  workerDropdownSettings = {
    idfield: 'workerId',
    textField: 'workerName',
    allowSearchFilter: true,
    enableCheckAll: false,
    itemsShowLimit: 8,
  }

  formProductItems;
  formProductUnitSize;
  formProductBrand;
  formProductCategory;

  systemMessage = '';

  @Input()
  searchQuery = '';
  @Input()
  searchLimit = 10;
  @Input()
  includeArchived: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    const data: SimpleChange = changes.data;
    if( data && data.currentValue && data.currentValue.date ) {
      this.getFormSettingName();
      this.isEditing = false;
      this.submitted = false;
      this.rejecting = false;
      this.rejectMessage = '';
      this.systemMessage = '';
      this.loading = true;
      if( data.currentValue.id ) {
        this.loading = false;
        data.currentValue.installers.forEach((single) => {
          single.id = single.workerId;
        });
        this.selectedInstallers = data.currentValue.installers;
        if( data.currentValue.installers.length > 0 ) {
          this.installerCountText = data.currentValue.installers.length + ' selected';
          data.currentValue.installers.forEach((single) => {
            this.selectedInstallersHolder.push(single.userCode);
          });
        }
      }
    }
  }

  ngOnInit() {
    const helper = new JwtHelperService();

    this.loadProductsData();

    let currentUser = this.authenticationService.currentUserValue;
    let currentUserRoles = [];
    if( helper.decodeToken(currentUser.jwt) && helper.decodeToken(currentUser.jwt).rol ) {
      currentUserRoles = helper.decodeToken(currentUser.jwt).rol;
    }

    if( currentUserRoles.includes('superadmin') ||
        currentUserRoles.includes('admin') ||
        currentUserRoles.includes('supervisor') ) {
      this.canApprove = true;
      this.canReject = true;
    }

    if( currentUserRoles.includes('superadmin') ||
        currentUserRoles.includes('admin') ||
        currentUserRoles.includes('supervisor') ||
        currentUserRoles.includes('leading-hand') ) {
      this.canEdit = true;
    }

    this.formSettingService.getConstructionSystem().pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.constructionSystemItems = data.data;
            this.constructionSystemItems.forEach((single) => {
              single.formSettingId = single.id;
              single.formSettingName = single.constructionSystemCode;
            });
          }
        },
        error => {}
      );
    this.formSettingService.getServiceType().pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.serviceTypeItems = data.data;
            this.serviceTypeItems.forEach((single) => {
              single.formSettingId = single.id;
              single.formSettingName = single.serviceTypeCode;
            });
          }
        },
        error => {}
      );
    this.formSettingService.getProduct().pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.productItems = data.data;
            this.productItems.forEach((single) => {
              single.formSettingId = single.id;
              single.formSettingName = single.productCode;
            });
          }
        },
        error => {}
      );

    this.formSettingService.getPenetrationRegisterMarker().pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if( data && data.data ) {
            this.ceilingSelectionItems = data.data.ceilingSelectionItems;
            // this.constructionSystemItems = data.data.constructionSystemItems;
            this.fireRatingItems = data.data.fireRatingItems;
            this.holeSizeItems = data.data.holeSizeItems;
            this.overlayItems = data.data.overlayItems;
            this.oversizeCavityItems = data.data.oversizeCavityItems;
            // this.productItems = data.data.productItems;
            // this.serviceTypeItems = data.data.serviceTypeItems;
          }
        },
        error => {
          this.loading = false;
        }
      );

    this.workerService.getWorkerList(false)
      .pipe(first())
      .subscribe(
        data => {
          if( data.data ) {
            this.workerList = data.data;
            this.workerList.forEach((worker) => {
              worker.workerId = worker.id;
              worker.workerName = worker.name;
            });
          }
        },
        error => {
        });
  }

  toggleClass(event, className) {
    if( event ) {
      let hasClass = event.target.parentElement.classList.contains(className);
      if(hasClass) {
        event.target.parentElement.classList.remove(className);
      } else {
        event.target.parentElement.classList.add(className);
      }
    }
  }

  close() {
    if( document.querySelector('.gf-datatable-wrap .is-active') ) {
      document.querySelector('.gf-datatable-wrap .is-active').classList.remove('is-active');
    }
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr: 'visibility', tabAttrValue: 'hidden'});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'width', tabAttrValue: 'calc((100% - 120px) - 3rem)'});
    } else {
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }

  getFormSettingName() {
    this.selectOversizeCavity = [];
    this.selectOversizeCavityOrig = [];
    this.selectServiceType = [];
    this.selectServiceTypeOrig = [];
    this.selectOverlay = [];
    this.selectOverlayOrig = [];
    this.selectHoleSize = [];
    this.selectHoleSizeOrig = [];
    this.selectConstructionSystem = [];
    this.selectConstructionSystemOrig = [];
    this.selectCeilingSelection = [];
    this.selectCeilingSelectionOrig = [];
    this.selectFireRating = [];
    this.selectFireRatingOrig = [];
    this.selectProduct = [];
    this.selectProductOrig = [];
    this.ceilingSelectionItems.forEach((single) => {
      if( single.formSettingId == this.data.ceilingSelectionFormSettingId ) {
        this.ceilingSelection = single.formSettingName;
        this.selectCeilingSelection.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
        this.selectCeilingSelectionOrig.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
      }
    });
    this.constructionSystemItems.forEach((single) => {
      // if( single.formSettingId == this.data.constructionSystemFormSettingId ) {
      if( this.data.constructionSystems.length && single.formSettingId == this.data.constructionSystems[0].id ) {
        this.constructionSystem = single.formSettingName;
        this.selectConstructionSystem.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
        this.selectConstructionSystemOrig.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
      }
    });
    this.fireRatingItems.forEach((single) => {
      if( single.formSettingId == this.data.fireRatingFormSettingId ) {
        this.fireRating = single.formSettingName;
        this.selectFireRating.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
        this.selectFireRatingOrig.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
      }
    });
    this.overlayItems.forEach((single) => {
      if( single.formSettingId == this.data.overlayFormSettingId ) {
        this.overlay = single.formSettingName;
        this.selectOverlay.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
        this.selectOverlayOrig.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
      }
    })
    this.oversizeCavityItems.forEach((single) => {
      if( single.formSettingId == this.data.oversizeCavitySizeFormSettingId ) {
        this.oversizeCavitySize = single.formSettingName;
        if( single.formSettingName !== 'N/A' ) {
          this.oversizeCavitySize += ' mm';
        }
        this.selectOversizeCavity.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
        this.selectOversizeCavityOrig.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
      }
    });
    this.holeSizeItems.forEach((single) => {
      if( single.formSettingId == this.data.penetrationHoleSizeFormSettingId ) {
        this.penetrationHoleSize = single.formSettingName;
        if( single.formSettingName !== 'N/A' ) {
          this.penetrationHoleSize += ' mm';
        }
        this.selectHoleSize.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
        this.selectHoleSizeOrig.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
      }
    });
    this.serviceTypeItems.forEach((single) => {
      // if( single.formSettingId == this.data.serviceTypeFormSettingId ) {
      if( this.data.serviceTypes.length && single.formSettingId == this.data.serviceTypes[0].id ) {
        this.serviceType = single.formSettingName;
        this.selectServiceType.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
        this.selectServiceTypeOrig.push({formSettingId: single.formSettingId, formSettingName: single.formSettingName});
      }
    });
    this.data.products.forEach((single) => {
      single.formSettingId = single.id;
      single.formSettingName = single.productCode;
    });
    this.selectProduct = this.data.products;
    this.selectProductOrig = this.data.products;
  }

  approve() {
    this.submitted = true;
    this.penetrationService.approvePenetration(this.penetrationId).pipe(first())
      .subscribe(
        data => {
          if( data ) {
            this.isEditing = false;
            this.submitted = false;
            this.rejectMessage = '';
            if( data.status == 400 ) {
              if( data.message ) {
                this.systemMessage = data.message;
              }
            } else {
              this.data.isApproved = true;
              this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'selectedPenetrationIndex', tabAttrValue: this.selectedPenetrationIndex + 1});
            }
          }
        },
        error => {
          console.log(error);
          this.submitted = false;
        }
      );
  }
  reject() {
    if( !this.rejecting ) {
      this.rejecting = true;
    } else {
      this.rejecting = false;
      this.submitted = true;
      this.penetrationService.rejectPenetration(this.penetrationId, this.rejectMessage).pipe(first())
        .subscribe(
          data => {
            this.data.isApproved = false;
            this.isEditing = false;
            this.submitted = false;
            this.rejectMessage = '';
            this.tabControl.next({tabString: 'penetrationRegister', tabAttr: 'selectedPenetrationIndex', tabAttrValue: this.selectedPenetrationIndex + 1});
          },
          error => {
            console.log(error);
            this.submitted = false;
          }
        );
    }
  }

  cancelEdit() {
    this.isEditing = false;
    this.selectOversizeCavity = this.selectOversizeCavityOrig;
    this.selectServiceType = this.selectServiceTypeOrig;
    this.selectOverlay = this.selectOverlayOrig;
    this.selectHoleSize = this.selectHoleSizeOrig;
    this.selectConstructionSystem = this.selectConstructionSystemOrig;
    this.selectCeilingSelection = this.selectCeilingSelectionOrig;
    this.selectFireRating = this.selectFireRatingOrig;
    this.selectProduct = this.selectProductOrig;
  }
  updateRP() {
    if( this.selectOversizeCavity.length > 0 &&
        this.selectServiceType.length > 0 &&
        this.selectOverlay.length > 0 &&
        this.selectHoleSize.length > 0 &&
        this.selectConstructionSystem.length > 0 &&
        this.selectCeilingSelection.length > 0 &&
        this.selectFireRating.length > 0 ) {
      this.submitted = true;
      this.selectProduct.forEach((single) => {
        single.id = single.formSettingId
      });
      let installersArray = [];
      this.selectedInstallers.forEach((worker) => {
        installersArray.push({workerId: worker.id});
      });
      let dataArray = this.data;
      dataArray.oversizeCavitySizeFormSettingId = this.selectOversizeCavity[0].formSettingId;
      dataArray.overlayFormSettingId = this.selectOverlay[0].formSettingId;
      dataArray.penetrationHoleSizeFormSettingId = this.selectHoleSize[0].formSettingId;
      dataArray.ceilingSelectionFormSettingId = this.selectCeilingSelection[0].formSettingId;
      dataArray.fireRatingFormSettingId = this.selectFireRating[0].formSettingId;
      dataArray.products = this.selectProduct;
      dataArray.constructionSystems = [
        {
          id: this.selectConstructionSystem[0].formSettingId
        }
      ];
      dataArray.serviceTypes = [
        {
          id: this.selectServiceType[0].formSettingId
        }
      ];
      dataArray.installers = installersArray;

      this.penetrationService.updateRegisterPenetration(this.penetrationId, dataArray).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.data = data.data;
              this.getFormSettingName();
            }
            this.submitted = false;
            this.isEditing = false;
          },
          error => {
            console.log(error);
            this.submitted = false;
          }
        );
    }
  }

  loadProductsData() {
    if( !this.formProductItems ) {
      this.formSettingService.getProduct().pipe(first())
  			.subscribe(
  				data => {
  					if( data && data.data ) {
  						this.formProductItems = data.data;
  					}
  				}
  			);
  		this.formSettingService.getProductBrand().pipe(first())
  			.subscribe(
  				data => {
  					if( data && data.data ) {
  						this.formProductBrand = data.data;
  					}
  				},
  				error => {
  					console.log(error);
  				}
  			);
  		this.formSettingService.getProductCategory().pipe(first())
  			.subscribe(
  				data => {
  					if( data && data.data ) {
  						this.formProductCategory = data.data;
  					}
  				},
  				error => {
  					console.log(error);
  				}
  			);
  		this.formSettingService.getProductUnit().pipe(first())
  			.subscribe(
  				data => {
  					if( data && data.data ) {
  						this.formProductUnitSize = data.data;
  					}
  				},
  				error => {
  					console.log(error);
  				}
  			);
    }
  }

  getProductInfo(productId) {
    let targetProduct = this.formProductItems.filter((single) => {
      return single.id == productId;
    });
    if( targetProduct && targetProduct.length > 0 ) {
      return targetProduct[0];
    }
  }

  onItemSelect(item: any, group = null) {
    if( group == 'installer' ) {
      this.selectedInstallersHolder.push(item.userCode);
      this.installerCountText = this.selectedInstallersHolder.length + ' selected';
    }
  }
  onItemDeSelect(item: any, group = null) {
    if( group == 'installer' ) {
      this.selectedInstallersHolder.splice(this.selectedInstallersHolder.indexOf(item.userCode), 1);
      if( this.selectedInstallersHolder.length < 1 ) {
        this.installerCountText = '';
      } else {
        this.installerCountText = this.selectedInstallersHolder.length + ' selected';
      }
    }
  }

  uploadPhoto(fileInput: any) {
    let fileData = fileInput.target.files[0];
    let formData = new FormData();
    formData.append('file', fileData);
    // formData.append('projectId', this.data.id);
    formData.append('registerPenetrationId', this.data.id);

    this.penetrationService.uploadImage(formData).pipe(first())
      .subscribe(
        data => {
          this.penetrationService.getPenetration(this.data.id).pipe(first())
            .subscribe(
              res => {
                if( res && res.data ) {
                  this.data = res.data;
                }
              }
            );
          this.fileInput = null;
        },
        error => {
          console.log(error);
        }
      );
  }
  removePhoto(index: any, photoId: any) {
    if( confirm('Delete image - are you sure?') ) {
      this.penetrationService.deleteImage(photoId).pipe(first())
        .subscribe(
          data => {
            this.data.registerPenetrationAfterPhotos.splice(index, 1);
          },
          error => {
            console.log(error);
          }
        );
    }
  }
}
