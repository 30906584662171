import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { LinearService } from '@app/_services/linear.service';
import { AuthenticationService } from '@app/_services';
import { JwtHelperService } from "@auth0/angular-jwt";

import { first } from 'rxjs/operators';

@Component({
  selector: '[gf-linear-details]',
  templateUrl: './linear-details.component.html',
  styleUrls: ['./linear-details.component.scss']
})
export class LinearDetailsComponent implements OnInit, OnChanges {

  loading: Boolean = true;
  isEditing: Boolean = false;
  submitted: Boolean = false;
  rejecting: Boolean = false;

	constructor(
    private linearService: LinearService,
    private authenticationService: AuthenticationService
	) { }

  @Input()
  linearId;
  @Input()
  selectedLinearIndex;
  @Input()
  data;
  @Input()
  hideActions = false;
  @Output()
  tabControl = new EventEmitter<any>();

  canApprove: Boolean = false;
  canReject: Boolean = false;
  canEdit: Boolean = false;

  oversizeCavityItems = [];
  serviceTypeItems = [];
  overlayItems = [];
  ceilingSelectionItems = [];
  constructionSystemItems = [];
  fireRatingItems = [];
  productItems = [];
  holeSizeItems = [];

  selectedProject = '';
  ceilingSelection = '';
  constructionSystem = '';
  fireRating = '';
  overlay = '';
  oversizeCavitySize = '';
  linearHoleSize = '';
  serviceType = '';
  rejectMessage = '';

  linearInputOrientation;
  linearInputSide;
  linearInputBackingMaterial;
  linearInputRiseHeight;
  linearInputRunLength;
  linearInputSteps;
  linearInputLandingLength;
  linearInputGapDepth;
  linearInputGapWidth;
  linearInputGapLength;

  @Input()
  searchQuery = '';
  @Input()
  searchLimit = 10;
  @Input()
  includeArchived: boolean = false;


  ngOnChanges(changes: SimpleChanges) {
    const data: SimpleChange = changes.data;
    if( data && data.currentValue && data.currentValue.date ) {
      this.isEditing = false;
      this.submitted = false;
      this.rejecting = false;
      this.rejectMessage = '';

      if( this.data.stairOrientationType == 'TopSide' ) {
        this.linearInputOrientation = 1;
      }
      if( this.data.stairOrientationType == 'UnderSide' ) {
        this.linearInputOrientation = 2;
      }
      if( this.data.stairIsSingleSide ) {
        this.linearInputSide = 1;
      } else {
        this.linearInputSide = 2;
      }
    }
  }

  ngOnInit() {
    const helper = new JwtHelperService();

    let currentUser = this.authenticationService.currentUserValue;
    let currentUserRoles = [];
    if( helper.decodeToken(currentUser.jwt) && helper.decodeToken(currentUser.jwt).rol ) {
      currentUserRoles = helper.decodeToken(currentUser.jwt).rol;
    }

    if( currentUserRoles.includes('superadmin') ||
        currentUserRoles.includes('admin') ||
        currentUserRoles.includes('supervisor') ) {
      this.canApprove = true;
      this.canReject = true;
    }

    if( currentUserRoles.includes('superadmin') ||
        currentUserRoles.includes('admin') ||
        currentUserRoles.includes('supervisor') ||
        currentUserRoles.includes('leading-hand') ) {
      this.canEdit = true;
    }
  }

  toggleClass(event, className) {
    if( event ) {
      let hasClass = event.target.parentElement.classList.contains(className);
      if(hasClass) {
        event.target.parentElement.classList.remove(className);
      } else {
        event.target.parentElement.classList.add(className);
      }
    }
  }

  close() {
    if( document.querySelector('.gf-datatable-wrap .is-active') ) {
      document.querySelector('.gf-datatable-wrap .is-active').classList.remove('is-active');
    }
    this.tabControl.next({tabString: 'linearRegister', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'linearDetails', tabAttr: 'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr: 'visibility', tabAttrValue: 'hidden'});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'linearRegister', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'linearRegister', tabAttr: 'width', tabAttrValue: 'calc((100% - 120px) - 3rem)'});
    } else {
      this.tabControl.next({tabString: 'linearRegister', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }

  approve() {
    this.submitted = true;
    this.linearService.approveLinear(this.linearId).pipe(first())
      .subscribe(
        data => {
          if( data ) {
            this.data.isApproved = true;
            this.isEditing = false;
            this.submitted = false;
            this.rejectMessage = '';
            this.tabControl.next({tabString: 'linearRegister', tabAttr: 'selectedLinearIndex', tabAttrValue: this.selectedLinearIndex + 1});
          }
        },
        error => {
          console.log(error);
          this.submitted = false;
        }
      );
  }
  reject() {
    if( !this.rejecting ) {
      this.rejecting = true;
    } else {
      this.rejecting = false;
      this.submitted = true;
      this.linearService.rejectLinear(this.linearId, this.rejectMessage).pipe(first())
        .subscribe(
          data => {
            this.data.isApproved = false;
            this.isEditing = false;
            this.submitted = false;
            this.rejectMessage = '';
            this.tabControl.next({tabString: 'linearRegister', tabAttr: 'selectedLinearIndex', tabAttrValue: this.selectedLinearIndex + 1});
          },
          error => {
            console.log(error);
            this.submitted = false;
          }
        );
    }
  }

  startEdit() {
    this.isEditing = true;
    this.linearInputGapWidth = this.data.gapWidth;
    this.linearInputGapDepth = this.data.gapDepth;
    this.linearInputGapLength = this.data.gapLength;
    this.linearInputRiseHeight = this.data.stairRiseHeight;
    this.linearInputRunLength = this.data.stairRunLength;
    this.linearInputSteps = this.data.stairNumberOfSteps;
    this.linearInputLandingLength = this.data.stairLandingLengthidth;
    if( this.data.stairOrientationType == 'TopSide' ) {
      this.linearInputOrientation = 1;
    }
    if( this.data.stairOrientationType == 'UnderSide' ) {
      this.linearInputOrientation = 2;
    }
    if( this.data.stairIsSingleSide ) {
      this.linearInputSide = 1;
    } else {
      this.linearInputSide = 2;
    }
    if( this.data.isBackingMaterialUsed ) {
      this.linearInputBackingMaterial = 1;
    } else {
      this.linearInputBackingMaterial = 2;
    }
  }
  cancelEdit() {
    this.isEditing = false;
    this.clearLinearForm();
  }
  updateRP() {
    let dataArray = {
      "gapWidth": this.linearInputGapWidth,
      "gapDepth": this.linearInputGapDepth,
      "gapLength": this.linearInputGapLength,
      "stairRiseHeight": this.linearInputRiseHeight,
      "stairRunLength": this.linearInputRunLength,
      "stairNumberOfSteps": this.linearInputSteps,
      "stairLandingLengthidth": this.linearInputLandingLength,
    };
    if( this.linearInputOrientation == 1 ) {
      dataArray['stairOrientationType'] = 'TopSide';
    }
    if( this.linearInputOrientation == 2 ) {
      dataArray['stairOrientationType'] = 'UnderSide';
    }
    if( this.linearInputSide == 1 ) {
      dataArray['stairIsSingleSide'] = true;
    }
    if( this.linearInputSide == 2 ) {
      dataArray['stairIsSingleSide'] = false;
    }
    if( this.linearInputBackingMaterial == 1 ) {
      dataArray['isBackingMaterialUsed'] = true;
    }
    if( this.linearInputBackingMaterial == 2 ) {
      dataArray['isBackingMaterialUsed'] = false;
    }
    this.linearService.updateLinear(this.linearId, dataArray).pipe(first())
      .subscribe(
        data => {
          this.isEditing = false;
          this.submitted = false;
          if( data && data.status == 204 ) {
            this.data.gapWidth = this.linearInputGapWidth;
            this.data.gapDepth = this.linearInputGapDepth;
            this.data.gapLength = this.linearInputGapLength;
            this.data.stairRiseHeight = this.linearInputRiseHeight;
            this.data.stairRunLength = this.linearInputRunLength;
            this.data.stairNumberOfSteps = this.linearInputSteps;
            this.data.stairLandingLengthidth = this.linearInputLandingLength;
            if( this.linearInputOrientation == 1 ) {
              this.data.stairOrientationType = 'TopSide';
            }
            if( this.linearInputOrientation == 2 ) {
              this.data.stairOrientationType = 'UnderSide';
            }
            if( this.linearInputSide == 1 ) {
              this.data.stairIsSingleSide = true;
            }
            if( this.linearInputSide == 2 ) {
              this.data.stairIsSingleSide = false;
            }
            if( this.linearInputBackingMaterial == 1 ) {
              this.data.isBackingMaterialUsed = true;
            }
            if( this.linearInputBackingMaterial == 2 ) {
              this.data.isBackingMaterialUsed = false;
            }
            this.clearLinearForm();
          }
        },
        error => {
          console.log(error);
          this.submitted = false;
        }
      );
  }

  clearLinearForm() {
    this.linearInputGapWidth = '';
    this.linearInputGapDepth = '';
    this.linearInputGapLength = '';
    this.linearInputRiseHeight = '';
    this.linearInputRunLength = '';
    this.linearInputSteps = '';
    this.linearInputLandingLength = '';
    this.linearInputOrientation = '';
    this.linearInputSide = '';
    this.linearInputBackingMaterial = '';
  }
}
