import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ProjectService } from '@app/_services/project.service';
import { WorkerService } from '@app/_services/worker.service';

@Component({
  selector: '[gf-project-form]',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit, OnChanges {
  projectForm: FormGroup;
  loading = false;
	submitted = false;
  error = '';

	constructor(
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private workerService: WorkerService
	) { }

  @Input()
  tabData;
  @Input()
  data;
  @Input()
  hideActions = false;
  @Output()
  tabControl = new EventEmitter<any>();

  opts: ISlimScrollOptions = {
    position: 'right',
    barBackground: '#ccc',
    barOpacity: '0.8',
    barWidth: '5',
    gridBackground: '#fff',
    alwaysVisible: false,
  };

  tempFloorName = '';
  tempFloorOrder = '';

  supervisorSearch = '';

  selectedSupervisors = [];
  selectedSupervisorsHolder = [];
  superviserCountText = '';
  selectedLeadingHands = [];
  selectedLeadingHandsHolder = [];
  leadingHandsCountText = '';
  selectedInstallers = [];
  selectedInstallersHolder = [];
  installerCountText = '';
  tempFloors = [];
  tempFloorsData = [];
  dropdownSettings = {
    idfield: 'id',
    textField: 'name',
    allowSearchFilter: true,
    enableCheckAll: false
  };
  workerDropdownSettings = {
    idfield: 'workerId',
    textField: 'workerName',
    allowSearchFilter: true,
    enableCheckAll: false,
    itemsShowLimit: 8,
  };
  floorDropdownSettings = {
    idfield: 'id',
    textField: 'name',
    allowSearchFilter: false
  }
  supervisorList = [];
  workerList = [];
  roomList = [];

  apartmentsSearch = '';
  apartmentSelection = [];
  apartmentText = '';
  apartmentArray = [];
  apartments = [];
  aptCountText = '';
  roomsSearch = '';
  roomDropdown = [];
  roomSelection = [];
  roomText = '';
  roomArray = [];
  rooms = [];
  roomsCountText = '';

  changedFloorIndexes = [];

  registerPenetration: Boolean = false;
  registerLinealStairs: Boolean = false;
  registerLinealDoors: Boolean = false;
  registerLinealWindows: Boolean = false;
  registerLinealOthers: Boolean = false;
  dayworksPenetration: Boolean = false;
  dayworksLinealStairs: Boolean = false;
  dayworksLinealDoors: Boolean = false;
  dayworksLinealWindows: Boolean = false;
  dayworksLinealOthers: Boolean = false;
  dayworksPreparation: Boolean = false;
  dayworksPenetrationAndPreparation: Boolean = false;

  @Input()
  searchQuery = '';
  @Input()
  searchLimit = 10;
  @Input()
  includeArchived: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    const data: SimpleChange = changes.data;

    this.rooms = [];
    this.roomText = '';
    this.roomSelection = [];
    this.roomDropdown = [];
    this.apartments = [];
    this.apartmentText = '';
    this.apartmentArray = [];
    this.apartmentSelection = [];
    this.roomList = [];
    this.aptCountText = '';
    this.roomsCountText = '';
    this.superviserCountText = '';
    this.leadingHandsCountText = '';
    this.installerCountText = '';

    if( data.currentValue.id ) {
      if( data.currentValue.allowedWorks ) {
        let allowedWorksArray = data.currentValue.allowedWorks;
        this.registerPenetration = allowedWorksArray.registerPenetration;
        this.registerLinealStairs = allowedWorksArray.registerLinealStairs;
        this.registerLinealDoors = allowedWorksArray.registerLinealDoors;
        this.registerLinealWindows = allowedWorksArray.registerLinealWindows;
        this.registerLinealOthers = allowedWorksArray.registerLinealOthers;
        this.dayworksPenetration = allowedWorksArray.dayworksPenetration;
        this.dayworksLinealStairs = allowedWorksArray.dayworksLinealStairs;
        this.dayworksLinealDoors = allowedWorksArray.dayworksLinealDoors;
        this.dayworksLinealWindows = allowedWorksArray.dayworksLinealWindows;
        this.dayworksLinealOthers = allowedWorksArray.dayworksLinealOthers;
        this.dayworksPreparation = allowedWorksArray.dayworksPreparation;
        this.dayworksPenetrationAndPreparation = allowedWorksArray.dayworksPenetrationAndPreparation;
      }

      if( data.currentValue.ps3 ) {
        this.f.ps3Date.setValue(data.currentValue.ps3.date);
        this.f.ps3BuildingConsentNumber.setValue(data.currentValue.ps3.buildingConsentNumber);
        this.f.ps3NZBCClauses.setValue(data.currentValue.ps3.nzbcClauses);
        this.f.ps3SiteAddress.setValue(data.currentValue.ps3.siteAddress);
        this.f.ps3LegalDescription.setValue(data.currentValue.ps3.legalDescription);
      }
      this.f.projectClientName.setValue(data.currentValue.clientName);
      this.f.projectNumber.setValue(data.currentValue.number);
      this.f.projectName.setValue(data.currentValue.name);
      this.f.projectAddress.setValue(data.currentValue.address);
      this.f.projectClientFirstName.setValue(data.currentValue.clientFirstName);
      this.f.projectClientLastName.setValue(data.currentValue.clientLastName);
      this.f.projectClientEmail.setValue(data.currentValue.clientEmail);
      this.f.projectClientPhone.setValue(data.currentValue.clientPhone);
      this.f.projectTypeId.setValue('');
      if( data.currentValue.supervisors ) {
        data.currentValue.supervisors.forEach((worker) => {
          worker.id = worker.workerId;
        });
        data.currentValue.installers.forEach((worker) => {
          worker.id = worker.workerId;
        });
        data.currentValue.leadingHands.forEach((worker) => {
          worker.id = worker.workerId;
        });
      }
      this.selectedSupervisors = data.currentValue.supervisors;
      this.selectedInstallers = data.currentValue.installers;
      this.selectedLeadingHands = data.currentValue.leadingHands;

      if( data.currentValue.rooms && data.currentValue.rooms.length > 0 ) {
        if( data.currentValue.rooms[0].apartment && data.currentValue.rooms[0].apartment.length > 0 ) {
          this.f.projectTypeId.setValue(1);
          let i = 0;
          let apartmentStored = [];
          data.currentValue.rooms.forEach((room) => {
            if( apartmentStored.indexOf(room.floorName + ' - ' + room.apartment) < 0 ) {
              apartmentStored.push(room.floorName + ' - ' + room.apartment);
              this.apartments.push(room.floorName + ' - ' + room.apartment);
              let roomData = {
                id: i,
                floorId: room.floorId,
                floorName: room.floorName,
                apartment: room.apartment,
                name: room.floorName + ' - ' + room.apartment
              };
              this.apartmentArray.push(roomData);
              i++;
            }
            this.rooms.push(room.floorName + ' - ' + room.apartment + ' - '+ room.name);
            if( this.f.projectTypeId.value == 1 ) {
              let roomArray = {
                id: room.id,
                floorId: room.floorId,
                apartment: room.apartment,
                name: room.name
              }
              this.roomList.push(roomArray);
            } else {
              data.currentValue.rooms.forEach((room) => {
                let roomArray = {
                  id: room.id,
                  floorId: room.floorId,
                  apartment: room.apartment,
                  name: room.name
                }
                this.roomList.push(roomArray);
              });
            }
          });
          this.roomDropdown = this.apartmentArray;
        } else {
          this.f.projectTypeId.setValue(2);
          this.roomDropdown = data.currentValue.floors;
          this.rooms = [];
          data.currentValue.rooms.forEach((single) => {
            this.rooms.push(single.displayName);
            let roomArray = {
              id: single.id,
              floorId: single.floorId,
              apartment: single.apartment,
              name: single.name
            }
            this.roomList.push(roomArray);
          });
        }
      } else {
        this.f.projectTypeId.setValue(0);
      }
    } else {
      if( this.projectForm ) {
        this.f.ps3Date.setValue('');
        this.f.ps3BuildingConsentNumber.setValue('');
        this.f.ps3NZBCClauses.setValue('');
        this.f.ps3SiteAddress.setValue('');
        this.f.ps3LegalDescription.setValue('');
        this.f.projectClientName.setValue('');
        this.f.projectNumber.setValue('');
        this.f.projectName.setValue('');
        this.f.projectAddress.setValue('');
        this.f.projectClientFirstName.setValue('');
        this.f.projectClientLastName.setValue('');
        this.f.projectClientEmail.setValue('');
        this.f.projectClientPhone.setValue('');
        this.f.projectTypeId.setValue('');
      }
      this.selectedSupervisors = [];
      this.selectedInstallers = [];
      this.selectedLeadingHands = [];
    }
  }

  ngOnInit() {
    this.projectForm = this.formBuilder.group({
			ps3Date: [''],
			ps3BuildingConsentNumber: [''],
      ps3NZBCClauses: [''],
      ps3SiteAddress: [''],
      ps3LegalDescription: [''],
      ps3DescriptionOfWork: [''],
      projectClientName: [''],
      projectNumber: [''],
      projectName: ['', Validators.required],
      projectAddress: [''],
      projectClientFirstName: [''],
      projectClientLastName: [''],
      projectClientEmail: [''],
      projectClientPhone: [''],
      projectTypeId: ['']
		});

    this.workerService.getWorkerList(true)
      .pipe(first())
      .subscribe(
        data => {
          if( data.data ) {
            this.supervisorList = data.data;
            this.supervisorList.forEach((worker) => {
              worker.workerId = worker.id;
              worker.workerName = worker.name;
            });
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
    this.workerService.getWorkerList(false)
      .pipe(first())
      .subscribe(
        data => {
          if( data.data ) {
            this.workerList = data.data;
            this.workerList.forEach((worker) => {
              worker.workerId = worker.id;
              worker.workerName = worker.name;
            });
          }
        },
        error => {
        });
  }

  toggleClass(event, className) {
    if( event ) {
      let hasClass = event.target.parentElement.classList.contains(className);
      if(hasClass) {
        event.target.parentElement.classList.remove(className);
      } else {
        event.target.parentElement.classList.add(className);
      }
    }
  }

  cancelEdit() {
    if( this.data ) {
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'visibility', tabAttrValue: 'visible'});
    } else {
      this.close();
    }
  }
  get f() { return this.projectForm.controls; }

  floorOrderChanged(index, evt) {
    this.data.floors[index].order = evt.target.value;
    if( !this.changedFloorIndexes.includes(index) ) {
      this.changedFloorIndexes.push(index);
    }
  }

  async updateFloors() {
    if( this.changedFloorIndexes.length > 0 ) {
      this.tempFloorsData = this.data.floors;
      try {
        new Promise((res, rej) => {
          this.changedFloorIndexes.forEach((floorIndex) => {
            this.projectService.updateFloor(this.data.floors[floorIndex].id, this.data.floors[floorIndex])
              .pipe(first())
              .subscribe();
            if( floorIndex == this.changedFloorIndexes.length - 1 ) {
              res(true);
            }
          });
        });
      } catch(err) {
        alert(err);
      }
    } else {
      return Promise.resolve(1);
    }
  }

	async onSubmit() {
    // stop here if form is invalid
		if (this.projectForm.invalid || this.submitted) {
      this.submitted = false;
			return;
    }

    this.submitted = true;

    let supervisorArray = [];
    let installersArray = [];
    let leadingHandsArray = [];
    this.selectedSupervisors.forEach((worker) => {
      supervisorArray.push({workerId: worker.id});
    });
    this.selectedInstallers.forEach((worker) => {
      installersArray.push({workerId: worker.id});
    });
    this.selectedLeadingHands.forEach((worker) => {
      leadingHandsArray.push({workerId: worker.id});
    });

    this.loading = true;
    let allowedWorks = {
      registerPenetration: this.registerPenetration,
      registerLinealStairs: this.registerLinealStairs,
      registerLinealDoors: this.registerLinealDoors,
      registerLinealWindows: this.registerLinealWindows,
      registerLinealOthers: this.registerLinealOthers,
      dayworksPenetration: this.dayworksPenetration,
      dayworksLinealStairs: this.dayworksLinealStairs,
      dayworksLinealDoors: this.dayworksLinealDoors,
      dayworksLinealWindows: this.dayworksLinealWindows,
      dayworksLinealOthers: this.dayworksLinealOthers,
      dayworksPreparation: this.dayworksPreparation,
      dayworksPenetrationAndPreparation: this.dayworksPenetrationAndPreparation
    }
    let projectArray = {
      name: this.f.projectName.value,
      number: this.f.projectNumber.value,
      address: this.f.projectAddress.value,
      clientName: this.f.projectClientName.value,
      clientFirstName: this.f.projectClientFirstName.value,
      clientLastName: this.f.projectClientLastName.value,
      clientPhone: this.f.projectClientPhone.value,
      clientEmail: this.f.projectClientEmail.value,
      supervisors: supervisorArray,
      installers: installersArray,
      leadingHands: leadingHandsArray,
      rooms: this.roomList,
      projectTypeId: this.f.projectTypeId.value,
      ps3: {
        date: this.f.ps3Date.value,
        buildingConsentNumber: this.f.ps3BuildingConsentNumber.value,
        NZBCClauses: this.f.ps3NZBCClauses.value,
        siteAddress: this.f.ps3SiteAddress.value,
        legalDescription: this.f.ps3LegalDescription.value,
        descriptionOfWork: this.f.ps3DescriptionOfWork.value,
      },
      allowedWorks: allowedWorks
    };

    this.updateFloors().then(() => {
      if( this.data ) {
        this.projectService.update(this.data.id, projectArray)
          .pipe(first())
          .subscribe(
            data => {
              if( data && data.data ) {
                this.afterSubmit(data);
              }
              this.submitted = false;
            },
            error => {
              this.error = error;
              this.loading = false;
              this.submitted = false;
            });
      } else {
        this.projectService.create(projectArray)
          .pipe(first())
          .subscribe(
            data => {
              if( data && data.data ) {
                this.afterSubmit(data);
              }
              this.submitted = false;
            },
            error => {
              this.error = error;
              this.loading = false;
              this.submitted = false;
            });
      }

    });
  }
  afterSubmit(responseData) {
    this.tabControl.next({tabString: 'projectList', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'visibility', tabAttrValue: 'visible'});
    this.tabControl.next({tabString: 'projectForm', tabAttr: 'visibility', tabAttrValue: 'hidden'});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: '40%'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
    } else {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }

    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'data', tabAttrValue: responseData.data});

    if( this.tempFloorsData ) {
      responseData.data.floors = this.tempFloorsData;
    }

    if( this.data.id ) {
      let projectIndex = 0;
      let targetIndex = 0;
      this.tabData.projectList.projects.forEach((single) => {
        if( single.id == this.data.id ) {
          targetIndex = projectIndex;
        }
        projectIndex++;
      });
      this.tabData.projectList.projects[targetIndex] = responseData.data;
    } else {
      this.tabData.projectList.projects.unshift(responseData.data);
    }
    this.tabControl.next({tabString: 'projectList', tabAttr: 'projects', tabAttrValue: this.tabData.projectList.projects});
    this.tabControl.next({tabString: 'projectList', tabAttr: 'selectedProject', tabAttrValue: responseData.data.id});
  }

  addTempFloor() {
    if( this.tempFloorName.length > 0) {
      this.tempFloors.push({'name': this.tempFloorName, 'order': this.tempFloorOrder, 'uploading': false});
      this.tempFloorName = '';
      this.tempFloorOrder = '';
    }
  }
  removeTempFloor(index) {
    this.tempFloors.splice(index, 1);
  }
  removeFloor(index: any, floor: any) {
    this.data.floors.splice(index, 1);
    this.projectService.deleteFloor(floor.id).pipe(first())
      .subscribe(
        data => {
        },
        error => {
        });
  }
  uploadFloor(index, fileInput: any) {
    this.tempFloors[index].uploading = true;
    let fileData = fileInput.target.files[0];

    let formData = new FormData();
    formData.append('file', fileData);
    formData.append('name', this.tempFloors[index].name);
    formData.append('order', this.tempFloors[index].order);
    formData.append('projectId', this.data.id);

    this.projectService.createFloor(formData).pipe(first())
      .subscribe(
        data => {
          this.tempFloors.splice(index, 1);
          if( data.data ) {
            this.dynamicUpdatePlan(data.data);
          }
        },
        error => {
          this.tempFloors[index].uploading = false;
        });
  }

  uncollapse() {
    this.tabControl.next({tabString: 'projectForm', tabAttr: 'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'projectForm', tabAttr: 'isCollapsed', tabAttrValue: false});
  }
  close() {
    if( window.innerWidth < 992 ) {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'left', tabAttrValue: '0'});
    }
    this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'projectList', tabAttr: 'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'projectForm', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'projectDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'width', tabAttrValue: '0'});
    this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'visibility', tabAttrValue: 'hidden'});
    if( document.querySelector('.row-project.is-active') ) {
      document.querySelector('.row-project.is-active').classList.remove('is-active');
    }
  }

  onItemSelect(item: any, group = null) {
    if( group == 'supervisor' ) {
      this.selectedSupervisorsHolder.push(item.workerName);
      this.superviserCountText = this.selectedSupervisorsHolder.length + ' selected';
    }
    if( group == 'leading' ) {
      this.selectedLeadingHandsHolder.push(item.workerName);
      this.leadingHandsCountText = this.selectedLeadingHandsHolder.length + ' selected';
    }
    if( group == 'installer' ) {
      this.selectedInstallersHolder.push(item.workerName);
      this.installerCountText = this.selectedInstallersHolder.length + ' selected';
    }
    if( group == 'rooms' ) {
      this.roomsCountText = this.roomSelection.length + ' selected';
    }
    if( group == 'apt' ) {
      this.aptCountText = this.apartmentSelection.length + ' selected';
    }
  }
  onItemSelectAll(item: any, group = null) {
    setTimeout(() => {
      if( group == 'rooms' ) {
        this.roomsCountText = this.roomSelection.length + ' selected';
      }
      if( group == 'apt' ) {
        this.aptCountText = this.apartmentSelection.length + ' selected';
      }
    }, 100);
  }
  onItemDeSelect(item: any, group = null) {
    if( group == 'supervisor' ) {
      this.selectedSupervisorsHolder.splice(this.selectedSupervisorsHolder.indexOf(item.workerName), 1);
      if( this.selectedSupervisorsHolder.length < 1 ) {
        this.superviserCountText = '';
      } else {
        this.superviserCountText = this.selectedSupervisorsHolder.length + ' selected';
      }
    }
    if( group == 'leading' ) {
      this.selectedLeadingHandsHolder.splice(this.selectedLeadingHandsHolder.indexOf(item.workerName), 1);
      if( this.selectedLeadingHandsHolder.length < 1 ) {
        this.leadingHandsCountText = '';
      } else {
        this.leadingHandsCountText = this.selectedLeadingHandsHolder.length + ' selected';
      }
    }
    if( group == 'installer' ) {
      this.selectedInstallersHolder.splice(this.selectedInstallersHolder.indexOf(item.workerName), 1);
      if( this.selectedInstallersHolder.length < 1 ) {
        this.installerCountText = '';
      } else {
        this.installerCountText = this.selectedInstallersHolder.length + ' selected';
      }
    }
    if( group == 'rooms' ) {
      if( this.roomSelection.length < 1 ) {
        this.roomsCountText = '';
      } else {
        this.roomsCountText = this.roomSelection.length + ' selected';
      }
    }
    if( group == 'apt' ) {
      if( this.apartmentSelection.length < 1 ) {
        this.aptCountText = '';
      } else {
        this.aptCountText = this.apartmentSelection.length + ' selected';
      }
    }
  }
  onItemDeSelectAll(item: any, group = null) {
    if( group == 'rooms' ) {
      this.roomsCountText = '';
    }
    if( group == 'apt' ) {
      this.aptCountText = '';
    }
  }

  removeSupervisor(index, workerName) {
    let targetIndex = this.selectedSupervisorsHolder.indexOf(workerName);
    let currentArr = this.selectedSupervisors;
    this.selectedSupervisorsHolder.splice(targetIndex, 1);
    currentArr.splice(targetIndex, 1);
    let tempArr = [];
    currentArr.forEach((item) => {
      tempArr.push(item);
    });
    this.selectedSupervisors = tempArr;
  }
  removeLeadingHand(index, workerName) {
    let targetIndex = this.selectedLeadingHandsHolder.indexOf(workerName);
    let currentArr = this.selectedLeadingHands;
    this.selectedLeadingHandsHolder.splice(targetIndex, 1);
    currentArr.splice(targetIndex, 1);
    let tempArr = [];
    currentArr.forEach((item) => {
      tempArr.push(item);
    });
    this.selectedLeadingHands = tempArr;
  }
  removeInstaller(index, workerName) {
    let targetIndex = this.selectedInstallersHolder.indexOf(workerName);
    let currentArr = this.selectedInstallers;
    this.selectedInstallersHolder.splice(targetIndex, 1);
    currentArr.splice(targetIndex, 1);
    let tempArr = [];
    currentArr.forEach((item) => {
      tempArr.push(item);
    });
    this.selectedInstallers = tempArr;
  }

  projectTypeChange(pType) {
    this.rooms = [];
    this.roomText = '';
    this.roomSelection = [];
    this.apartments = [];
    this.apartmentText = '';
    this.apartmentSelection = [];
    this.roomList = [];
    if( pType == 2 ) {
      this.roomDropdown = this.data.floors;
    } else {
      this.roomDropdown = [];
    }
  }

  addApartments() {
    if( this.apartmentSelection && this.apartmentSelection.length > 0 && this.apartmentText.length > 0 ) {
      let i = this.apartmentArray.length;
      let tempRoomArray = [];
      this.apartmentSelection.forEach((item) => {
        if( this.apartmentText.includes('-') && this.apartmentText.split('-').length == 2 ) {
          let range = this.apartmentText.split('-');
          for( let z = <any>range[0]; z <= range[1]; z++ ) {
            this.apartments.push(item.name +' - '+ z);
            let data = {
              id: i,
              floorId: item.id,
              floorName: item.name,
              apartment: z,
              name: item.name + ' - ' + z
            };
            this.apartmentArray.push(data);
          }
        } else {
          this.apartmentText.split(',').map(item => item.trim()).forEach((floor) => {
            this.apartments.push(item.name +' - '+ floor);
            let data = {
              id: i,
              floorId: item.id,
              floorName: item.name,
              apartment: floor,
              name: item.name + ' - ' + floor
            };
            this.apartmentArray.push(data);
            i++;
          });
        }
      });
      this.roomDropdown = this.apartmentArray;
      let tempRoomDropdown = [];
      this.roomDropdown.forEach((single) => {
        tempRoomDropdown.push(single);
      });

      this.roomDropdown = tempRoomDropdown;
      this.apartmentText = '';
    }
  }
  removeApartment(name) {
    let targetIndex = this.apartments.indexOf(name);
    let roomTargetIndexes = [];
    let i = 0;
    this.apartments.splice(targetIndex, 1);
    this.apartmentArray.splice(targetIndex, 1);
    this.rooms.forEach((room, index) => {
      if( room.indexOf(name) >= 0 ) {
        roomTargetIndexes.push(index);
      }
    });
    let ind = 0;
    roomTargetIndexes.forEach((item) => {
      item = item - ind;
      this.rooms.splice(item, 1);
      this.roomList.splice(item, 1);
      ind++;
    });
    if( this.apartmentsSearch && this.apartmentsSearch.length > 0 ) {
      let origKeyword = this.apartmentsSearch;
      this.apartmentsSearch = '';
      setTimeout(() => {
        this.apartmentsSearch = origKeyword;
      }, 1);
    }
    if( this.roomsSearch && this.roomsSearch.length > 0 ) {
      let origRoomKeyword = this.roomsSearch;
      this.roomsSearch = this.roomsSearch + ' ';
      setTimeout(() => {
        this.roomsSearch = origRoomKeyword;
      }, 1);
    }

    let tempRoomDropdown = [];
    this.roomDropdown.forEach((single) => {
      tempRoomDropdown.push(single);
    });

    this.roomDropdown = tempRoomDropdown;
  }
  addRoom() {
    if( this.roomSelection && this.roomSelection.length > 0 && this.roomText.length > 0 ) {
      this.roomSelection.forEach((item, key) => {
        this.roomText.split(',').map(item => item.trim()).forEach((room) => {
          this.rooms.push(item.name + ' - '+ room);
          if( this.f.projectTypeId.value == 1 ) {
            let roomArray = {
              floorId: this.apartmentArray[item.id].floorId,
              apartment: this.apartmentArray[item.id].apartment,
              name: room
            };
            this.roomList.push(roomArray);
          }
          if( this.f.projectTypeId.value == 2 ) {
            let roomArray = {
              floorId: item.id,
              name: room
            }
            this.roomList.push(roomArray);
          }
        });
      });
      this.roomText = '';
    }
  }
  removeRoom(name, index) {
    let targetIndex = this.rooms.indexOf(name);
    if( targetIndex >= 0 ) {
      this.rooms.splice(targetIndex, 1);
      this.roomList.splice(targetIndex, 1);
    }
    if( this.roomsSearch && this.roomsSearch.length > 0 ) {
      let origKeyword = this.roomsSearch;
      this.roomsSearch = this.roomsSearch + ' ';
      setTimeout(() => {
        this.roomsSearch = origKeyword;
      }, 1);
    }
  }

  dynamicUpdatePlan(plan) {
    let currentArr = this.data.floors;
    currentArr.push(plan);
    let tempArr = [];
    currentArr.forEach((item) => {
      tempArr.push(item);
    });
    this.data.floors = tempArr;
  }
}
