import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { first } from 'rxjs/operators';
import { ProjectService } from '@app/_services/project.service';

@Component({
  selector: '[gf-project-list]',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {

  loading:Boolean = true;

	constructor(
    private projectService: ProjectService,
    private location: Location,
    private router: Router
	) { }

  @Input()
  projects;
  @Input()
  hideActions = false;
  @Input()
  selectedProject;
  @Input()
  isCollapsed = false;
  @Output()
  tabControl = new EventEmitter<any>();

  projectsSearch;
  action = 'wrench';
  @Input()
  searchQuery = '';
  @Input()
  searchLimit = 10;
  @Input()
  includeArchived: boolean = false;

  ngOnInit() {
    // @ts-ignore
    jQuery(function ($) {
      setTimeout(function() {
        $('[data-toggle="tooltip"]').tooltip();
      }, 500);
    });

    this.getProject();
  }

  getProject() {
    this.loading = true;
    // this.tabControl.next({tabString: 'projectList', tabAttr: 'projects', tabAttrValue: ''});
    this.projectService.getProjectList(this.includeArchived, this.searchLimit, this.searchQuery).pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.tabControl.next({tabString: 'projectList', tabAttr: 'projects', tabAttrValue: data.data});
            this.loading = false;
            if( this.selectedProject ) {
              let selectedProjectData = data.data.filter((single) => {
                return single.id == this.selectedProject;
              });
              if( selectedProjectData && selectedProjectData.length > 0 ) {
                this.showProject(selectedProjectData[0]);
              }
            }
          }
        },
        error => {
          this.loading = false;
        });
  }

  searchTimeout;

  searchProject() {
    this.loading = true;
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.getProject();
    }, 200);
  }

  changeTab(tabString) {
    this.action = tabString;
    // @ts-ignore
    jQuery(function ($) {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  addProject() {
    this.action = '';
    this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: '40%'});
    this.tabControl.next({tabString: 'projectList', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'projectDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'projectForm', tabAttr:'visibility', tabAttrValue: 'visible'});
    this.tabControl.next({tabString: 'projectForm', tabAttr:'data', tabAttrValue: ''});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: '40%'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'projectForm', tabAttr:'left', tabAttrValue: 'calc(40% + 1rem)'});
    } else {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
    if( document.querySelector('.row-project.is-active') ) {
      document.querySelector('.row-project.is-active').classList.remove('is-active');
    }
  }
  showProject(project) {
    // this.location.replaceState('/project/'+ project.id);
    // this.router.navigate(['/project/'+ project.id]);

    if( document.querySelectorAll('.dropdown-form-wrap.is-active') ) {
      document.querySelectorAll('.dropdown-form-wrap.is-active').forEach((elem) => {
        elem.classList.remove('is-active');
      });
    }
    this.action = '';
    this.hideActions = true;
    this.selectedProject = project.id;
    this.tabControl.next({tabString: 'projectList', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'visibility', tabAttrValue: 'visible'});
    this.tabControl.next({tabString: 'projectForm', tabAttr: 'visibility', tabAttrValue: 'hidden'});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: '40%'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
    } else {
      this.tabControl.next({tabString: 'projectList', tabAttr: 'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'projectForm', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }

    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'projectId', tabAttrValue: this.selectedProject});
  }

  uncollapse() {
    if( this.isCollapsed ) {
      if( window.innerWidth < 992 ) {
        this.tabControl.next({tabString: 'projectList', tabAttr: 'left', tabAttrValue: '0'});
      }
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'hideActions', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectForm', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'width', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'penetrationRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'width', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'penetrationDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'width', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr:'width', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'left', tabAttrValue: '100%'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'width', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'dayworkMarkers', tabAttr:'visibility', tabAttrValue: 'hidden'});
      if( document.querySelector('.row-project.is-active') ) {
        document.querySelector('.row-project.is-active').classList.remove('is-active');
      }
    }
  }

  updateUrl(projectId) {
    this.tabControl.next({tabString: 'projectList', tabAttr: 'searchQuery', tabAttrValue: encodeURI(this.searchQuery)});
    this.tabControl.next({tabString: 'projectList', tabAttr: 'searchLimit', tabAttrValue: this.searchLimit});
    this.tabControl.next({tabString: 'projectList', tabAttr: 'includeArchived', tabAttrValue: this.includeArchived});
    this.location.replaceState('/project/'+ encodeURI(this.searchQuery) +' /'+ this.searchLimit +'/'+ this.includeArchived +'/'+ projectId);
    // this.router.navigate(['/project/'+ projectId]);
  }
}
