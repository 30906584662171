import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { MessageService } from '@app/_services/message.service';
import { first } from 'rxjs/operators';

import { CronOptions } from "cron-editor/cron-editor";

import { NotificationService } from '@app/_services/notification.service';
import { WorkerService } from '@app/_services/worker.service';

@Component({
  selector: 'gf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentRoute = '/';
  unReadMessages = 0;

  notificationLoading: Boolean = false;
  showNotifications: Boolean = false;
  showNotificationForm: Boolean = false;

  cronExpression = '0 0 0 1/1 * ? *';

  cronOptions = {
    formInputClass: 'form-control cron-editor-input',
    formSelectClass: 'form-control cron-editor-select',
    formRadioClass: 'cron-editor-radio',
    formCheckboxClass: 'cron-editor-checkbox',

    defaultTime: "00:00:00",

    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: true,
    hideSpecificWeekDayTab : false,
    hideSpecificMonthWeekTab : false,

    use24HourTime: true,
    hideSeconds: false,

    cronFlavor: "standard" //standard or quartz
  };
  targetID = '';
  targetIndex = '';
  workerList = [];
  workerDropdownSettings = {
    idfield: 'workerId',
    textField: 'workerName',
    allowSearchFilter: true,
    enableCheckAll: false
  };
  includeAlso = [];
  notificationTitle = '';
  notificationMessage = '';
  scheduledNotifications = [];

	constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private workerService: WorkerService
	) {
    router.events.subscribe((val) => {
      this.currentRoute = this.router.url;
      if( this.currentRoute.includes('/notifications') ) {
        this.openNotifications();
      }
    });
  }

  ngOnInit() {
    this.messageService.getUnreadMessageCount().pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.unReadMessages = data.data;
          }
        },
        error => {
          console.log(error);
        });
    setInterval(() => {
      this.messageService.getUnreadMessageCount().pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.unReadMessages = data.data;
            }
          },
          error => {
            console.log(error);
          });
    }, 300000);
  }

  logout() {
    this.authenticationService.logout().subscribe(
      data => {
        this.router.navigate(['/login']);
      }
    );
  }

  openNotifications() {
    this.showNotifications = true;
    this.notificationLoading = true;
    this.notificationService.getNotificationList().pipe(first())
      .subscribe(
        data => {
          if( data && data.data ) {
            this.scheduledNotifications = data.data;
            this.notificationLoading = false;
          }
        },
        error => {
          console.log(error);
        });
    this.workerService.getWorkerList()
      .pipe(first())
      .subscribe(
        data => {
          if( data.data ) {
            this.workerList = data.data;
            this.workerList.forEach((worker) => {
              worker.workerId = worker.id;
              worker.workerName = worker.name;
            });
          }
        },
        error => {
        });
  }
  closeNotifications() {
    this.showNotifications = false;
  }
  openNotificationForm() {
    this.showNotificationForm = true;
  }
  closeNotificationForm() {
    this.showNotificationForm = false;
    this.includeAlso = [];
    this.notificationTitle = '';
    this.notificationMessage = '';
    this.cronExpression = '0 0 0 1/1 * ? *';
    this.targetID = '';
  }
  saveNotification() {
    this.includeAlso.forEach((worker) => {
      worker.workerId = worker.id;
    });

    let dataArray = {
      "title": this.notificationTitle,
      "message": this.notificationMessage,
      "cronExpression": this.cronExpression,
      "scheduledNotificationRecipients": this.includeAlso
    };

    if( this.targetID ) {
      this.notificationService.updateNotification(this.targetID, dataArray).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.scheduledNotifications[this.targetIndex] = data.data;
              this.closeNotificationForm();

              console.log(this.scheduledNotifications);
            }
          },
          error => {
            console.log(error);
          });
    } else {
      this.notificationService.createNotification(dataArray).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.scheduledNotifications.unshift(data.data);
              this.closeNotificationForm();
            }
          },
          error => {
            console.log(error);
          });
    }
  }
  editNotification(index, targetData) {
    this.targetID = targetData.id;
    this.targetIndex = index;
    this.notificationTitle = targetData.title;
    this.notificationMessage = targetData.message;
    this.cronExpression = targetData.cronExpression;
    if( targetData.scheduledNotificationRecipients ) {
      this.includeAlso = targetData.scheduledNotificationRecipients;
      this.includeAlso.forEach((worker) => {
        worker.id = worker.workerId;
      });
    }
    this.openNotificationForm();
  }
  removeNotification(index, notificationId) {
    this.scheduledNotifications.splice(index, 1);
    this.notificationService.deleteNotification(notificationId).pipe(first())
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

}
