import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatDate, Location } from '@angular/common';
import { LinearService } from '@app/_services/linear.service';

import { first } from 'rxjs/operators';

@Component({
  selector: '[gf-linear-register]',
  templateUrl: './linear-register.component.html',
  styleUrls: ['./linear-register.component.scss']
})
export class LinearRegisterComponent implements OnInit, OnChanges {

  loading: Boolean = false;

  showUnapproved: Boolean = true;
  showApproved: Boolean = true;
  showDayworks: Boolean = false;

  dateRange = 1;
  dateStartInput;
  dateEndInput;
  thisMonthStart;
  thisMonthEnd;
  lastMonthStart;
  lastMonthEnd;

  @Input()
  projectId;
  @Input()
  selectedLinearIndex;
  @Input()
  tabData;
  @Input()
  data;
  @Input()
  hideActions = true;
  @Output()
  tabControl = new EventEmitter<any>();

  locationString = '';
  linearIndex: number;

  linears = [];
  selectedLinear;
  checkedLinear = [];
  collapsedWidth = '';
  bulkCheck;
  toggleDayworks = 2;

  @Input()
  searchQuery = '';
  @Input()
  searchLimit = 10;
  @Input()
  includeArchived: boolean = false;

	constructor(
    private linearService: LinearService,
    private route: ActivatedRoute,
    private location: Location
	) {
    let tms = new Date();
    if( tms.getDate() >= 16 ) {
      tms.setMonth(tms.getMonth() + 1);
    }
    tms.setDate(16);
    tms.setMonth(tms.getMonth() - 1);
    if( tms.getMonth() < 1 ) {
      tms.setFullYear(tms.getFullYear() - 1);
    }
    this.thisMonthStart = tms;
    let tme = new Date();
    if( tme.getDate() >= 16 ) {
      tme.setMonth(tme.getMonth() + 1);
    }
    tme.setDate(15);
    this.thisMonthEnd = tme;
    let lms = new Date();
    if( lms.getDate() >= 16 ) {
      lms.setMonth(lms.getMonth() + 1);
    }
    lms.setDate(16);
    lms.setMonth(lms.getMonth() - 2);
    if( lms.getMonth() < 1 ) {
      lms.setFullYear(lms.getFullYear() - 1);
    }
    this.lastMonthStart = lms;
    let lme = new Date();
    if( lme.getDate() >= 16 ) {
      lme.setMonth(lme.getMonth() + 1);
    }
    lme.setDate(15);
    lme.setMonth(lme.getMonth() - 1);
    if( lme.getMonth() < 1 ) {
      lme.setFullYear(lme.getFullYear() - 1);
    }
    this.lastMonthEnd = lme;

    this.route.paramMap.subscribe(params => {
      if( params.get('projectId') ) {
        this.projectId = params.get('projectId');
        if( params.get('approval') ) {
          if( params.get('approval') == 'approved' ) {
            this.showUnapproved = false;
            this.showApproved = true;
          } else if( params.get('approval') == 'unapproved' ) {
            this.showUnapproved = true;
            this.showApproved = false;
          } else {
            this.showUnapproved = true;
            this.showApproved = true;
          }
        }
  			if( params.get('datePeriod') ) {
          if( params.get('datePeriod') == 'last-month' ) {
            this.dateRange = 2;
          } else if( params.get('datePeriod') == 'custom' ) {
            this.dateRange = 0;
          } else {
            this.dateRange = 1;
          }
  			}
  			if( params.get('dateStart') && params.get('dateStart') != 'null' ) {
  				this.dateStartInput = params.get('dateStart');
  			}
  			if( params.get('dateEnd') && params.get('dateEnd') != 'null' ) {
  				this.dateEndInput = params.get('dateEnd');
  			}
  			if( params.get('linearIndex') ) {
          this.linearIndex = parseInt(params.get('linearIndex'));
  			}
      }
		});
  }

  ngOnChanges(changes: SimpleChanges) {
    const projectId: SimpleChange = changes.projectId;
    const selectedLinearIndex: SimpleChange = changes.selectedLinearIndex;
    if( projectId && projectId.currentValue ) {
      this.linears = [];
      this.getLinearList();
    }
    if( selectedLinearIndex && selectedLinearIndex.currentValue > 0 && (selectedLinearIndex.currentValue != selectedLinearIndex.previousValue) ) {
      if( this.linears[selectedLinearIndex.currentValue] ) {
        this.selectLinear(selectedLinearIndex.currentValue, this.linears[selectedLinearIndex.currentValue]);
      }
    }
  }

  ngOnInit() {
    let headerElement = <HTMLElement>document.querySelector('.header');
    let cardHeaderWidth = window.innerHeight - headerElement.offsetHeight;
    this.collapsedWidth = 'calc('+ cardHeaderWidth +'px - 2rem)';
  }

  customRange() {
    if( this.dateStartInput || this.dateEndInput ) {
      this.dateRange = 0;
    }
  }
  presetRange() {
    this.dateStartInput = '';
    this.dateEndInput = '';
  }

  getLinearList() {
    if( !this.loading ) {
      this.loading = true;

      let dateStart;
      let dateEnd;
      this.route.paramMap.subscribe(params => {
        this.locationString = '/project/'+ this.searchQuery +' /'+ this.searchLimit +'/'+ this.includeArchived +'/'+ this.projectId +'/linear-register/';
      });

      if( this.showUnapproved && !this.showApproved ) {
        this.locationString += 'unapproved/';
      } else if( !this.showUnapproved && this.showApproved ) {
        this.locationString += 'approved/';
      } else {
        this.locationString += 'all/';
      }
      // if( this.dateRange == 0 ) {
      //   this.locationString += 'custom/';
      //   if( this.dateStartInput ) {
      //     dateStart  = formatDate(this.dateStartInput, 'yyyy-MM-dd', 'en');
      //     this.locationString += dateStart +'/';
      //   } else {
      //     this.locationString += 'null/';
      //   }
      //   if( this.dateEndInput ) {
      //     dateEnd = formatDate(this.dateEndInput, 'yyyy-MM-dd', 'en');
      //     this.locationString += dateEnd;
      //   } else {
      //     this.locationString += 'null';
      //   }
      // }
      // if( this.dateRange == 1 ) {
      //   this.locationString += 'this-month';
      //   if( this.thisMonthStart )
      //     dateStart  = formatDate(this.thisMonthStart, 'yyyy-MM-dd', 'en');
      //   if( this.thisMonthEnd )
      //     dateEnd = formatDate(this.thisMonthEnd, 'yyyy-MM-dd', 'en');
      // }
      // if( this.dateRange == 2 ) {
      //   this.locationString += 'last-month';
      //   if( this.lastMonthStart )
      //     dateStart  = formatDate(this.lastMonthStart, 'yyyy-MM-dd', 'en');
      //   if( this.lastMonthEnd )
      //     dateEnd = formatDate(this.lastMonthEnd, 'yyyy-MM-dd', 'en');
      // }

      if( this.toggleDayworks == 1 ) {
        this.showDayworks = false;
      } else {
        this.showDayworks = true;
      }

      this.location.replaceState(this.locationString);

      this.linearService.getList(this.projectId, this.showUnapproved, this.showApproved, this.showDayworks).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              this.linears = data.data;
              this.linears.forEach((single) => {
                if( single.linearTypeId == 1 )
                  single.linearType = 'Door';
                if( single.linearTypeId == 2 )
                  single.linearType = 'Edge-of-wall joint';
                if( single.linearTypeId == 3 )
                  single.linearType = 'Head-of-wall joint';
                if( single.linearTypeId == 4 )
                  single.linearType = 'Trapezium joint';
                if( single.linearTypeId == 5 )
                  single.linearType = 'Vertical Joint';
                if( single.linearTypeId == 6 )
                  single.linearType = 'Stairs';
                if( single.linearTypeId == 7 )
                  single.linearType = 'Window';
              });

              if( this.linearIndex && data.data[this.linearIndex - 1] ) {
                this.selectLinear(this.linearIndex - 1, data.data[this.linearIndex - 1]);
              }
              this.linearIndex = null;
            }
            this.loading = false;
          },
          error => {
            this.loading = false;
          });
    }
  }
  selectLinear(index, linear) {
    if( (this.selectedLinear && this.selectedLinear.id !== linear.id) || !this.selectedLinear ) {
      this.selectedLinear = linear;
      this.selectedLinearIndex = index;
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'linearId', tabAttrValue: linear.id});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'data', tabAttrValue: {number: linear.number}});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'selectedLinearIndex', tabAttrValue: index});
      this.showLinearDetail();

      this.linearService.getLinear(linear.id).pipe(first())
        .subscribe(
          data => {
            if( data && data.data ) {
              if( data.data.linearTypeId == 1 )
                data.data.linearType = 'Door';
              if( data.data.linearTypeId == 2 )
                data.data.linearType = 'Edge-of-wall joint';
              if( data.data.linearTypeId == 3 )
                data.data.linearType = 'Head-of-wall joint';
              if( data.data.linearTypeId == 4 )
                data.data.linearType = 'Trapezium joint';
              if( data.data.linearTypeId == 5 )
                data.data.linearType = 'Vertical Joint';
              if( data.data.linearTypeId == 6 )
                data.data.linearType = 'Stairs';
              if( data.data.linearTypeId == 7 )
                data.data.linearType = 'Window';
              this.tabControl.next({tabString: 'linearDetails', tabAttr: 'data', tabAttrValue: data.data});
            }
          },
          error => {
          });

      this.location.replaceState(this.locationString + '/' + parseInt(index + 1));
    }
  }

  bulkSelectToggle() {
    if( this.bulkCheck ) {
      this.linears.forEach(linear => {
        if( !this.checkedLinear.includes(linear.id) ) {
          this.checkedLinear.push(linear.id);
        }
      });
    } else {
      this.checkedLinear = [];
    }
  }
  toggleCheckedLinear(linearId) {
    if( this.checkedLinear.includes(linearId) ) {
      this.checkedLinear.splice(this.checkedLinear.indexOf(linearId), 1);
    } else {
      this.checkedLinear.push(linearId);
    }
    if( this.checkedLinear.length == 0 ) {
      this.bulkCheck = false;
    }
  }
  generateReport() {
    if( !this.loading ) {
      this.loading = true;
      let reportData = {
        projectId: this.projectId,
        ids: this.checkedLinear,
        isLinear: true
      }
      this.linearService.sendLinearReport(reportData).pipe(first())
        .subscribe(
          data => {
            this.checkedLinear = [];
            this.bulkCheck = false;
            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        );
    }
  }

  showLinearDetail() {
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'linearRegister', tabAttr: 'hideActions', tabAttrValue: false});
    this.tabControl.next({tabString: 'linearRegister', tabAttr: 'left', tabAttrValue: 'calc(120px + 2rem)'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});

    this.tabControl.next({tabString: 'linearDetails', tabAttr: 'visibility', tabAttrValue: 'visible'});

    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: true});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
      this.tabControl.next({tabString: 'linearRegister', tabAttr: 'left', tabAttrValue: 'calc(120px + 2rem)'});
      this.tabControl.next({tabString: 'linearRegister', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'left', tabAttrValue: 'calc(50% + 60px + 3rem)'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'width', tabAttrValue: 'calc(50% - 120px - 0.25rem)'});
    } else {
      this.tabControl.next({tabString: 'linearRegister', tabAttr: 'left', tabAttrValue: '-100%'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }

  collapse() {
    this.tabControl.next({tabString: 'linearRegister', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'linearRegister', tabAttr: 'isCollapsed', tabAttrValue: true});
    this.tabControl.next({tabString: 'linearRegister', tabAttr: 'width', tabAttrValue: this.collapsedWidth});
    this.tabControl.next({tabString: 'linearDetails', tabAttr: 'left', tabAttrValue: 'calc(180px + 3rem)'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr: 'width', tabAttrValue: 'calc((100% - 180px) - 4rem)'});
  }
  uncollapse() {
    this.tabControl.next({tabString: 'linearRegister', tabAttr: 'isCollapsed', tabAttrValue: false});
    this.tabControl.next({tabString: 'linearRegister', tabAttr: 'hideActions', tabAttrValue: false});
    if( this.tabData.linearDetails.visibility == 'visible' ) {
      this.tabControl.next({tabString: 'linearRegister', tabAttr: 'width', tabAttrValue: 'calc(50% - 60px)'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'left', tabAttrValue: 'calc(50% + 60px + 3rem)'});
      this.tabControl.next({tabString: 'linearDetails', tabAttr: 'width', tabAttrValue: 'calc(50% - 120px - 0.25rem)'});
    }
  }

  close() {
    if( document.querySelector('.gf-datatable-wrap .is-active') ) {
      document.querySelector('.gf-datatable-wrap .is-active').classList.remove('is-active');
    }
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideActions', tabAttrValue: true});
    this.tabControl.next({tabString: 'projectDetails', tabAttr: 'hideProjectContacts', tabAttrValue: false});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'left', tabAttrValue: '100%'});
    this.tabControl.next({tabString: 'linearRegister', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'visibility', tabAttrValue: 'hidden'});
    this.tabControl.next({tabString: 'linearDetails', tabAttr:'left', tabAttrValue: '100%'});
    if( window.innerWidth >= 992 ) {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'isCollapsed', tabAttrValue: false});
      this.tabControl.next({tabString: 'projectList', tabAttr: 'width', tabAttrValue: 'calc(40%)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: 'calc(40% + 1rem)'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(60% - 2rem)'});
    } else {
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'left', tabAttrValue: '0'});
      this.tabControl.next({tabString: 'projectDetails', tabAttr: 'width', tabAttrValue: 'calc(100% - 1rem)'});
    }
  }
}
